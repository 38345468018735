import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import HeaderAndRoute from "../HeaderAndRoute";
import { auth } from "@mitim/react-mitim";

export const LogoutButton = withRouter(
	({ component: Component, history, match, location, ...rest }) =>
		auth.isAuthenticated() && (
			<Component
				{...rest}
				onClick={() => {
					auth.signout(() => history.push("/"));
				}}
			/>
		)
);

export const PrivateRoute = ({
	component: Component,
	authorities,
	...rest
}) => (
	<HeaderAndRoute
		{...rest}
		render={props =>
			auth.userHaveAuthorities(authorities) ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);

export const LoginRoute = ({ component: Component, ...rest }) => (
	<HeaderAndRoute
		{...rest}
		render={props =>
			!auth.isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: "/",
						state: { from: props.location }
					}}
				/>
			)
		}
	/>
);
