// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
// Component
import Page from "../Page";
import TextField from "@material-ui/core/TextField";

class MonCompte extends Page {
	state = {
		data: {},
		nom: "",
		prenom: "",
		email: ""
	};

	constructor(props) {
		super(props);
	}

	render() {
		const user = JSON.parse(localStorage.getItem("user"));
		const { t } = this.props;
		return (
			<div className="container pb-5">
				<h2 className="text-center pt-5 pb-5">Informations personnelles</h2>
				<div className="row pb-5">
					<div className="col-md-2"></div>
					<div className="col-md-8">
						<form noValidate autoComplete="off">
							<div>
								<TextField
									className="d-block pb-4"
									id="outlined-read-only-input"
									label="Prénom"
									defaultValue={user.firstname}
									InputProps={{
										readOnly: true
									}}
									variant="outlined"
								/>
								<TextField
									className="d-block pb-4"
									id="outlined-read-only-input"
									label="Nom"
									defaultValue={user.lastname}
									InputProps={{
										readOnly: true
									}}
									variant="outlined"
								/>
								<TextField
									className="d-block pb-4"
									id="outlined-read-only-input"
									label="E-Mail"
									defaultValue={user.email}
									InputProps={{
										readOnly: true
									}}
									variant="outlined"
								/>
							</div>
						</form>
					</div>
					<div className="col-md-2"></div>
				</div>
			</div>
		);
	}
}

export default withTranslation()(MonCompte);
