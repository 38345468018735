import React from 'react';
import MetaTags from 'react-meta-tags';

export default class MetaData extends React.Component {
	render() {
		return (
			<MetaTags>
				<title>{this.props.title}</title>
				<meta name="description" content={this.props.descriptionMeta} />
			</MetaTags>
		);
	}
}
