// Libraries
import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
// Component
import Page from "../Page";
import CustomInput, { HelperTextWithIcon, DateMask } from "../CustomInput";
import { titleCase } from "../../utils/functions";
import { auth } from "@mitim/react-mitim";
import Modal from "react-bootstrap/Modal";

class Inscription extends Page {
	state = {
		nom: { value: "", errorMessage: null },
		email: { value: "", errorMessage: null },
		prenom: { value: "", errorMessage: null },
		password: { value: "", errorMessage: null },
		passwordConfirm: { value: "", errorMessage: null },
		dateNaissance: { value: "", errorMessage: null },
		lieuNaissance: { value: "", errorMessage: null },
		telMobile: { value: "", errorMessage: null },
		passwordLengthKo: true,
		passwordCharKo: true,
		passwordNameKo: false,
		message: "",
		show: false
	};

	constructor(props) {
		super(props);
		this.passwordConfirm = React.createRef();
		this.password = React.createRef();
		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);
	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow() {
		this.setState({ show: true });
	}

	handleChange = event => {
		let target = event.target;
		let value = target.value;
		let action = target.dataset.parse;
		let id = target.id;
		if (action === "upperCase") {
			value = value.toUpperCase();
		} else if (action === "lowerCase") {
			value = value.toLowerCase();
		} else if (action === "titleCase") {
			value = titleCase(value);
		}

		this.setState({ [target.name]: { value: value } }, () => {
			if (
				["nom", "mail", "prenom"].includes(id) ||
				target === this.password.current
			) {
				let { lengthOk, charOk, nameOk } = this.checkPasswordStrengh(
					this.password.current.value
				);
				this.setState({
					passwordCharKo: !charOk,
					passwordLengthKo: !lengthOk,
					passwordNameKo: !nameOk
				});

				if (!lengthOk || !charOk || !nameOk) {
					this.password.current.setCustomValidity("Invalid");
				} else {
					this.password.current.setCustomValidity("");
				}
			} else if (target === this.passwordConfirm.current) {
				this.checkSamePassword();
				this.passwordConfirm.current.checkValidity();
			}
		});
	};

	handleSubmit = event => {
		event.preventDefault();
		this.checkPasswordStrengh(this.password.current.value);
		this.checkSamePassword();
		if (!event.target.checkValidity()) {
			// form is invalid! so we do nothing
			return;
		}
		console.log("form valid");

		auth.inscriptionUser(this, data => {
			this.setState({
				nom: { value: "", errorMessage: null },
				email: { value: "", errorMessage: null },
				prenom: { value: "", errorMessage: null },
				password: { value: "", errorMessage: null },
				passwordConfirm: { value: "", errorMessage: null },
				dateNaissance: { value: "", errorMessage: null },
				lieuNaissance: { value: "", errorMessage: null },
				telMobile: { value: "", errorMessage: null },
				passwordLengthKo: true,
				passwordCharKo: true,
				passwordNameKo: false,
				message: data.message,
				show: true
			});
			// this.handleShow();
		});

		// TODO Make the inscription to the API
	};

	handleInvalid = event => {
		this.setState({
			[event.target.name]: {
				errorMessage: event.target.validationMessage,
				value: event.target.value
			}
		});
	};

	checkPasswordStrengh = password => {
		// Minimum 8 char
		let lengthOk = password.length >= 8;
		let charOk = true,
			nameKo = false;
		// Contain 1 digit, 1 letter lower case and 1 letter upper case
		charOk = charOk && /\d/.test(password);
		charOk = charOk && /[a-z]/.test(password);
		charOk = charOk && /[A-Z]/.test(password);
		// Doesn't contain nom, prenom or mail
		if (this.state.nom.value !== "") {
			nameKo = nameKo || password.toUpperCase().includes(this.state.nom.value);
		}
		if (this.state.prenom.value !== "") {
			nameKo = nameKo || titleCase(password).includes(this.state.prenom.value);
		}
		if (this.state.email.value !== "") {
			// On regarde uniquement la partie avant le @
			let mail = this.state.email.value.split("@");
			nameKo = nameKo || password.toLowerCase().includes(mail[0]);
		}
		return { lengthOk: lengthOk, charOk: charOk, nameOk: !nameKo };
	};

	checkSamePassword = () => {
		if (this.passwordConfirm.current.value !== this.password.current.value) {
			this.passwordConfirm.current.setCustomValidity(
				"inscription.password-check-mismatch"
			);
		} else {
			this.passwordConfirm.current.setCustomValidity("");
		}
	};

	render() {
		const { t } = this.props;

		return (
			<div className="container">
				<h2 className="text-center pt-5 pb-5">{t("menu.inscription")}</h2>
				<div className="row pb-5">
					<div className="col-md-2"></div>
					<div className="col-md-8">
						<form
							onSubmit={this.handleSubmit}
							noValidate
							method="POST"
							action="/create"
							className="pl-2 pr-2"
						>
							<CustomInput
								required
								id="email"
								label={t("inscription.email")}
								type="email"
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.email}
								dataparse="lowerCase"
								autoComplete="username email"
							/>
							<CustomInput
								required
								id="prenom"
								label={t("inscription.prenom")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.prenom}
								dataparse="titleCase"
								autoComplete="given-name"
							/>
							<CustomInput
								required
								id="nom"
								label={t("inscription.nom")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.nom}
								dataparse="upperCase"
								autoComplete="family-name"
							/>
							<CustomInput
								required
								id="password"
								label={t("inscription.password")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.password}
								type="password"
								reference={this.password}
								dataparse="checkStrengh"
								autoComplete="new-password"
							>
								<HelperTextWithIcon error={this.state.passwordLengthKo}>
									{t("inscription.password-check-length")}
								</HelperTextWithIcon>
								<HelperTextWithIcon error={this.state.passwordCharKo}>
									{t("inscription.password-check-chars")}
								</HelperTextWithIcon>
								<HelperTextWithIcon error={this.state.passwordNameKo}>
									{t("inscription.password-check-name")}
								</HelperTextWithIcon>
							</CustomInput>
							<CustomInput
								required
								id="passwordConfirm"
								label={t("inscription.password-confirm")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.passwordConfirm}
								type="password"
								reference={this.passwordConfirm}
								dataparse="checkConfirmation"
								autoComplete="new-password"
							/>
							<CustomInput
								required
								id="dateNaissance"
								label={t("inscription.date-naissance")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.dateNaissance}
								placeholder="JJ/MM/AAAA"
								inputComponent={DateMask}
								autoComplete="bday"
							/>
							<CustomInput
								required
								id="lieuNaissance"
								label={t("inscription.lieu-naissance")}
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.lieuNaissance}
								dataparse="upperCase"
							/>
							<CustomInput
								required
								id="telMobile"
								label="Téléphone mobile"
								onChange={this.handleChange}
								onInvalid={this.handleInvalid}
								state={this.state.telMobile}
								dataparse="upperCase"
							/>
							<div className="row">
								<div class="col-auto mr-auto"></div>
								<div className="col-auto mt-5">
									<Button variant="contained" color="secondary" type="submit">
										{t("inscription.inscription")}
									</Button>
								</div>
							</div>
						</form>
					</div>
					<div className="col-md-2"></div>
				</div>
				<Modal show={this.state.show} onHide={this.handleClose}>
					<Modal.Body>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={this.handleClose}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<p className="text-center">{this.state.message}</p>
					</Modal.Body>
				</Modal>
			</div>
		);
	}
}

export default withTranslation()(Inscription);
