import React from "react";
import { withTranslation } from "react-i18next";
import BackgroundSlider from "react-background-slider";
import SlideTemplate1 from "./SlideTemplate1";
import SlideTemplate2 from "./SlideTemplate2";
import SlideTemplate3 from "./SlideTemplate3";

class Slide extends React.Component {
	render() {
		return (
			<>
				{this.props.slide.template === 1 && (
					<SlideTemplate1 slide={this.props.slide} />
				)}
				{this.props.slide.template === 2 && (
					<SlideTemplate2 slide={this.props.slide} />
				)}
				{this.props.slide.template === 3 && (
					<SlideTemplate3 slide={this.props.slide} />
				)}
			</>
		);
	}
}
export default withTranslation()(Slide);
