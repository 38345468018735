// Libraries
import React from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from "react-router-dom";
import $ from "jquery";
import Footer from "./Footer/Footer";
import BarrePolitique from "./PolitiqueConfidentialite/BarrePolitique";
import HeaderAndRoute from "./HeaderAndRoute";
import Home from "./Home";
import Login from "./Login/Login";
import Inscription from "./Login/Inscription";
import MonCompte from "./Login/MonCompte";
import CompteOublie from "./Login/CompteOublie";
import ChangerMotDePasse from "./Login/ChangerMotDePasse";
import ConnectLink from "./Login/ConnectLink";
import Contact from "./Contact";
import PolitiqueConfidentialite from "./PolitiqueConfidentialite/PolitiqueConfidentialite";
import HisoireEtMemoire from "./Catalogue/HistoireEtMemoire";
import ReformeEtReveil from "./Catalogue/ReformeEtReveil";
import Temoins from "./Catalogue/Temoins";
import Livre from "./Livre/Livre";

// import { Sandbox } from "./Sandbox/Sandbox";
// import { PrivateRoute, LoginRoute, ROLE_CANDIDAT } from "./Login/Auth";
import { PrivateRoute, LoginRoute } from "./Login/PrivateHeaders";
import "@mitim/react-mitim/dist/styles.css";

// CSS
import "../styles/App.scss";
import "../styles/Font.scss";
import "../styles/Media.scss";

$(document).ready(function() {
	/* ---------------------------------------------- /*
		* Scroll top
		/* ---------------------------------------------- */

	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$(".scroll-up").fadeIn();
		} else {
			$(".scroll-up").fadeOut();
		}
	});

	$('a[href="#totop"]').click(function() {
		$("html, body").animate(
			{
				scrollTop: 0
			},
			"slow"
		);
		return false;
	});
});

export default class App extends React.Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Switch>
						{/* DIVERS *************************************************** */}
						{/* cookies */}
						<HeaderAndRoute
							path="/cookies"
							component={PolitiqueConfidentialite}
							slide={{
								template: 3,
								image: "contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Politique de confidentialité",
								descriptionMeta: "Politique de confidentialité"
							}}
						/>

						{/* Connexion */}
						<LoginRoute
							path="/login"
							component={Login}
							titre="menu.login"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Page d'authentification",
								descriptionMeta: "Page d'authentification"
							}}
						/>
						<LoginRoute
							path="/inscription"
							component={Inscription}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Créer un compte",
								descriptionMeta: "Créer un compte"
							}}
						/>
						<HeaderAndRoute
							path="/mon-compte"
							component={MonCompte}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Mon compte",
								descriptionMeta: "Créer un compte"
							}}
						/>
						<LoginRoute
							path="/compte-oublie"
							component={CompteOublie}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Informations de compte oubliées",
								descriptionMeta: "Informations de compte oubliées"
							}}
						/>
						<HeaderAndRoute
							path="/changer-mot-de-passe/:token"
							component={ChangerMotDePasse}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Changement de mot de passe",
								descriptionMeta: "Changement de mot de passe"
							}}
						/>

						<HeaderAndRoute
							path="/connexion/:token"
							component={ConnectLink}
							titre="menu.inscription"
							slide={{
								template: 2,
								image: "contact.jpg",
								// icone: "icone_contactlettres.svg",
								h1: "Connexion",
								descriptionMeta: "Connexion"
							}}
						/>

						{/* ******************************************************************* */}

						{/* CONTACT */}
						<HeaderAndRoute
							path="/contact"
							component={Contact}
							slide={{
								logo: 4,
								template: 2,
								image: "contact.jpg",
								icone: "icone_contactlettres.svg",
								h1: "Contact",
								descriptionMeta:
									"Vous pouvez nous contacter par voie postale, par téléphone ou en envoyant un message via le formulaire."
							}}
						/>
						{/*  */}
						<HeaderAndRoute
							path="/catalogue/reforme-et-reveil"
							slide={{
								logo: 1,
								num: 0,
								template: 2,
								image: "reformeetreveil.jpg",
								descriptionMeta:
									"Portée par un retour aux fondements du protestantisme, la collection accueille des textes de la Réforme et du Réveil"
							}}
							component={ReformeEtReveil}
						/>
						{/*  */}
						<HeaderAndRoute
							path="/catalogue/temoins"
							slide={{
								logo: 2,
								num: 0,
								template: 2,
								image: "temoins.jpg",
								descriptionMeta:
									"Cette collection donne la parole à des hommes et des femmes qui ont été des témoins de la foi dans l’Histoire. Par leur vie et par leur(s) texte(s), ils ont incarné un message d’espérance qui nous parle encore aujourd’hui."
							}}
							component={Temoins}
						/>
						{/*  */}
						<HeaderAndRoute
							path="/catalogue/histoire-et-memoire"
							slide={{
								logo: 3,
								num: 0,
								template: 2,
								image: "histoireetmemoire.jpg",
								descriptionMeta:
									"À la croisée de l’Histoire et de la mémoire, nous rééditons dans cette collection des livres qui ont marqué l’historiographie protestante."
							}}
							component={HisoireEtMemoire}
						/>

						{/*  */}
						<HeaderAndRoute
							path="/livre/:id"
							slide={{
								template: 3
							}}
							component={Livre}
						/>

						{/* HOME */}
						<HeaderAndRoute
							path="/"
							slide={{
								template: 1,
								image: "pageaccueil.jpg",
								descriptionMeta:
									"Calvin éditions est une maison d’édition protestante créée en 2020. Par ses publications, elle souhaite faire vivre l’héritage de la Réforme et du Réveil."
							}}
							component={Home}
						/>
					</Switch>
					<Footer />
					<BarrePolitique />
					<div className="scroll-up">
						<a href="#totop">
							<i className="fa fa-angle-double-up" />
						</a>
					</div>
				</div>
			</Router>
		);
	}
}
