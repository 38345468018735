import React from "react";
import { withTranslation } from "react-i18next";
import "./SlideTemplate2.scss";
import { Link } from "react-router-dom";
import { tools } from "@mitim/react-mitim";

const LogoCalvinEdition = props => (
	<svg
		width={props.width || 40}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 1279.93 296.13"
	>
		<defs>
			<style
				dangerouslySetInnerHTML={{
					__html:
						".logoCalvinEdition12{fill:none;}.logoCalvinEdition22{clip-path:url(#cplogoCalvinEdition2);}.logoCalvinEdition32{fill:" +
						props.color +
						";}"
				}}
			/>
			<clipPath id="cplogoCalvinEdition2" transform="translate(-0.07 -358.02)">
				<rect
					className="logoCalvinEdition12"
					x="0.07"
					y="358.02"
					width="1279.93"
					height="296.13"
				/>
			</clipPath>
		</defs>
		<g className="logoCalvinEdition22">
			<path
				className="logoCalvinEdition32"
				d="M10,402.15a11,11,0,0,0,1.07,4.93A8.23,8.23,0,0,0,14,410.44a7.34,7.34,0,0,0,4.09,1.21,6.1,6.1,0,0,0,3.62-1,8.77,8.77,0,0,0,2.4-2.5,38.09,38.09,0,0,0,2.12-3.86,21.75,21.75,0,0,1,2.53-4.41,3.72,3.72,0,0,1,3.06-1.46,4.48,4.48,0,0,1,2.41.71,5,5,0,0,1,1.82,2,6.76,6.76,0,0,1,.68,3.07A10.27,10.27,0,0,1,36,407.9a10.57,10.57,0,0,1-2.29,3.52l.22.5,3.73.45a15,15,0,0,0,1.52-3.84,16.14,16.14,0,0,0,.53-4,11.12,11.12,0,0,0-1.14-5.09A8.66,8.66,0,0,0,35.48,396a7.57,7.57,0,0,0-4.18-1.23,6.11,6.11,0,0,0-3.63,1.07,9.32,9.32,0,0,0-2.48,2.57,33,33,0,0,0-2.12,3.95c-.63,1.31-1.18,2.34-1.63,3.09a7.15,7.15,0,0,1-1.62,1.89,3.34,3.34,0,0,1-2.16.75,4.32,4.32,0,0,1-2.27-.66,4.82,4.82,0,0,1-1.77-1.91,6,6,0,0,1-.68-2.93,8.46,8.46,0,0,1,.54-2.93A10.65,10.65,0,0,1,15,396.88l-.18-.65-3.5-.59A17.34,17.34,0,0,0,10,402.15m.14,23.63.27,3.41,23.14,0v.32l-23.32,15v3.78l20.5-.09,8.64.13v-3.27l-9.59.05L16,445.06v-.23l23.32-15.09v-3.82L30.8,426Zm2.77,48.42c0-3,1-5.19,3-6.66s5-2.21,9-2.21q12,0,12,9.1a7.74,7.74,0,0,1-3,6.66q-3,2.21-9.23,2.2-11.73,0-11.73-9.09m-3-.22q0,6.27,3.91,9.65T25,487c4.69,0,8.32-1.07,10.86-3.21s3.82-5.17,3.82-9.11q0-6.31-4-9.73t-11.48-3.41q-6.91,0-10.59,3.21T9.94,474m.27,25.5v3.72L30.58,503l8.77.22v-3.68l-9.09.18Zm0,16.45v19.78L13,536l.36-.23-.27-5.18v-3l17.46-.22,8.77.27V524l-9.09.13L13.12,524v-3l.27-5.09-2.82-.27Zm0,31.91v3.73l20.37-.23,8.77.23v-3.68l-9.09.18Zm26.37,30.28c0,.73,0,1.75-.07,3.07s-.12,2.45-.21,3.38l-6,.14-17-.18q-.19-3.72-.19-6.59,0-9.42,11.46-9.41c4,0,7,.83,9,2.47a8.69,8.69,0,0,1,3,7.12M10,577.67l.18,10.5v0L30.58,588l8.77.23v0c.09-1.52.16-3.16.2-4.94s.07-3.27.07-4.52c0-4.49-1.36-7.92-4.09-10.32s-6.65-3.59-11.78-3.59q-6.63,0-10.18,3.32T10,577.67m-10,26.91c1.55,2.6,3.1,5.5,4.68,8.68l1.64-.64.18-.41q-1.91-4.59-3.91-8.91l-.36-.09-2.14.91ZM36.17,601l.18,7-.05,5.77-6,.14-4.33,0,0-5.18.09-5.09-2.82-.32-.32.27.14,4.82-.09,5.46-9.54-.09-.09-5.78.13-6.86-2.82-.28-.36.19V617.4l20.37-.23,8.77.23V601l-2.82-.27Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M1128.47,536.79"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M1262.18,478q0-16-8.42-23.09t-23.65-7.09a53.62,53.62,0,0,0-26.38,6.39,61.75,61.75,0,0,0-19.28,16.3l0-20.66h-45.17v11.54q17.41,0,17.42,9.12v61.77q0,9.12-17.42,9.12V553H1198V541.44q-13.58,0-13.57-9.12V490.6a49.94,49.94,0,0,1,14.78-19.24,39.2,39.2,0,0,1,9.83-5.67,28.32,28.32,0,0,1,10.43-2,12.89,12.89,0,0,1,10.93,5.47q4.05,5.47,4.05,18.23v45q0,9.12-13.57,9.12V553H1280V541.44q-17.82,0-17.82-9.12Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M1023.34,437.89a14.41,14.41,0,0,0-10.54-4.4,14.81,14.81,0,0,0-10.63,4.31,14.79,14.79,0,0,0-3.25,16.51,15.65,15.65,0,0,0,8,8,14.84,14.84,0,0,0,16.51-3.25,14.83,14.83,0,0,0,4.31-10.63,14.39,14.39,0,0,0-4.39-10.54m2.28,40.05H986.44v10q15.12,0,15.11,8.08v53.41q0,7.89-15.11,7.9v10h54.46v-10q-15.63,0-15.28-7.9Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M888.12,455.72H849.65v10h6.67q7.56,0,7.56,4.75a5.52,5.52,0,0,1-.71,2.8L839.46,530.2,816.1,471.88a6.94,6.94,0,0,1-.53-2.81c0-2.22,1.69-3.34,5.09-3.34h9.48v-10H779.2v10q9.66,0,12.3,6.15l30.21,73.25h22.67l30.21-73.43a8.35,8.35,0,0,1,4.83-4.48,22.8,22.8,0,0,1,8.7-1.49Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M665.59,432.79H626.42v10q15.11,0,15.11,7.9v99.42q0,7.92-15.11,7.91v10h54.45V558q-15.27,0-15.28-7.91Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M486.63,520.54a30.82,30.82,0,0,1-26.17,14.23q-9,0-13.35-3.52t-4.39-9.83a14.29,14.29,0,0,1,2.63-8.79,22.31,22.31,0,0,1,8.09-6.23,79.34,79.34,0,0,1,13.7-5.09q8.25-2.38,19.49-5.19Zm23-35.95q0-9.51-3.43-15.76A26.26,26.26,0,0,0,497,459a39.61,39.61,0,0,0-13.35-5.1,80.16,80.16,0,0,0-15.64-1.5,67.9,67.9,0,0,0-17.82,2.14,45.7,45.7,0,0,0-13.09,5.63A27.09,27.09,0,0,0,429,468a16.17,16.17,0,0,0-2.8,8.78q0,5.79,3.07,9t9.22,3.25q6.15,0,9.31-3.07a10.64,10.64,0,0,0,3.17-8,7.87,7.87,0,0,0-2.82-6.5,12.23,12.23,0,0,0-6.85-2.64,27.55,27.55,0,0,1,9.75-4.74,54.81,54.81,0,0,1,15-1.76,23,23,0,0,1,9.14,1.67,17.38,17.38,0,0,1,10.1,11.33,29.08,29.08,0,0,1,1.14,8.26v5.44q-7.72,2.12-15.54,3.52t-15.12,2.9q-7.29,1.49-13.7,3.34a40.73,40.73,0,0,0-11.24,5,23.58,23.58,0,0,0-7.56,7.82,22.89,22.89,0,0,0-2.72,11.68q0,11.6,7.64,18.36t24.16,6.76a50.22,50.22,0,0,0,12.83-1.58,44.25,44.25,0,0,0,10.71-4.31,37,37,0,0,0,8.17-6.23,29.77,29.77,0,0,0,5.36-7.38,39.41,39.41,0,0,0,1.93,6.68,17.48,17.48,0,0,0,3.51,5.61,17,17,0,0,0,5.62,4,19.79,19.79,0,0,0,8.08,1.49,27.85,27.85,0,0,0,7.82-1.05,15.21,15.21,0,0,0,6.23-3.52,15.7,15.7,0,0,0,4.05-6.51,30.49,30.49,0,0,0,1.4-9.86v-7.92h-8.25v10.38a11.61,11.61,0,0,1-1.23,5.37,4,4,0,0,1-3.69,2.38q-5.28,0-5.27-7.75Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition32"
				d="M354.77,364.51H326.29l-9.62,24q-11.18-12-33.3-21.24T230.05,358a173.5,173.5,0,0,0-57,9.17A128.37,128.37,0,0,0,126.7,394.9q-19.85,18.53-31.19,47T84.16,509q0,37.07,11.55,64.38t31.75,45.27a131.4,131.4,0,0,0,47.16,26.76,186.64,186.64,0,0,0,58.12,8.78q29.64,0,51.21-8.2t36.56-21.56A114.38,114.38,0,0,0,345,593.86a178.77,178.77,0,0,0,14.82-35.49L334,549.59q-10.39,33.58-33.5,52.09t-63.13,18.51a97,97,0,0,1-37.14-6.87,75.94,75.94,0,0,1-28.87-20.8q-12.14-13.92-18.86-35.1t-6.74-49.8a197.78,197.78,0,0,1,5.39-47.7q5.38-21.76,16.74-37.4a77.56,77.56,0,0,1,29.45-24.43q18.09-8.76,43.5-8.78,34.65,0,56.39,19.47t29.45,51.52l28.1-2.29Z"
				transform="translate(-0.07 -358.02)"
			/>
		</g>
	</svg>
);

const LogoTemoins = props => (
	<svg
		width={props.width || 40}
		version="1.1"
		id="Calque_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1106 284"
		style={{ enableBackground: "new 0 0 1106 284" }}
		xmlSpace="preserve"
	>
		<style
			type="text/css"
			dangerouslySetInnerHTML={{
				__html:
					"\n\t\t.st0{clip-path:url(#SVGID_2_);}\n\t\t.st1{opacity:0.34;clip-path:url(#SVGID_4_);}\n\t\t.st2{clip-path:url(#SVGID_6_);fill:#FFFFFF;}\n\t\t.st3{opacity:0.42;clip-path:url(#SVGID_4_);}\n\t\t.st4{clip-path:url(#SVGID_8_);fill:#DCA415;}\n\t\t.st5{opacity:0.56;clip-path:url(#SVGID_4_);}\n\t\t.st6{clip-path:url(#SVGID_10_);fill:none;stroke:#FFFFFF;stroke-width:2.835;}\n\t\t.st7{clip-path:url(#SVGID_2_);fill:#FFFFFF;}\n\t\t.st8{clip-path:url(#SVGID_12_);fill:#DCA415;}\n\t\t.st9{clip-path:url(#SVGID_12_);fill:#FFFFFF;}\n\t"
			}}
		/>
		<g>
			<defs>
				<rect id="SVGID_1_" x="0.2" width="1105.2" height={284} />
			</defs>
			<clipPath id="SVGID_2_">
				<use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
			</clipPath>
			<g className="st0">
				<defs>
					<rect id="SVGID_3_" x="0.2" width="1105.2" height={284} />
				</defs>
				<clipPath id="SVGID_4_">
					<use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
				</clipPath>
				<g className="st1">
					<g>
						<defs>
							<rect
								id="SVGID_5_"
								x="-10.1"
								y="57.9"
								width="1125.8"
								height={187}
							/>
						</defs>
						<clipPath id="SVGID_6_">
							<use xlinkHref="#SVGID_5_" style={{ overflow: "visible" }} />
						</clipPath>
						<rect
							x="0.2"
							y="65.9"
							className="st2"
							width="1105.2"
							height="177.8"
						/>
					</g>
				</g>
				<g className="st3">
					<g>
						<defs>
							<rect
								id="SVGID_7_"
								x="123.9"
								y="174.2"
								width="518.7"
								height="120.2"
							/>
						</defs>
						<clipPath id="SVGID_8_">
							<use xlinkHref="#SVGID_7_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st4"
							points="538.8,184.5 125,184.5 220.2,284 634.6,284 				"
						/>
					</g>
				</g>
				<g className="st5">
					<g>
						<defs>
							<rect
								id="SVGID_9_"
								x="202.3"
								y="6.9"
								width="648.3"
								height={225}
							/>
						</defs>
						<clipPath id="SVGID_10_">
							<use xlinkHref="#SVGID_9_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st6"
							points="692.7,219.9 205.9,219.9 205,27.8 839.2,27.8 				"
						/>
					</g>
				</g>
			</g>
			<rect x="568.8" className="st7" width="329.7" height="65.9" />
			<g className="st0">
				<defs>
					<rect id="SVGID_11_" x="0.2" width="1105.2" height={284} />
				</defs>
				<clipPath id="SVGID_12_">
					<use xlinkHref="#SVGID_11_" style={{ overflow: "visible" }} />
				</clipPath>
				<path
					className="st8"
					d="M816.2,53.2h15.4v-5.1h-9.8v-11h7.7v-5.1h-7.7v-9.5h9.8v-5.1h-15.4V53.2z M802.3,53.8c5.5,0,8.4-3.3,8.4-9
				V17.3h-5.4v27.8c0,2.6-1.1,3.5-2.9,3.5c-1.8,0-2.9-0.9-2.9-3.5V17.3h-5.6v27.4C793.8,50.5,796.7,53.8,802.3,53.8 M780.5,53.8
				c5.5,0,8.4-3.3,8.4-9V33h-8.2v5.1h2.8v7c0,2.6-1.1,3.5-2.9,3.5c-1.8,0-2.9-0.9-2.9-3.5V25.5c0-2.6,1.1-3.5,2.9-3.5
				c1.8,0,2.9,1,2.9,3.5v3.4h5.3v-3.1c0-5.7-2.9-9-8.4-9s-8.4,3.3-8.4,9v18.9C772,50.5,774.9,53.8,780.5,53.8 M758.5,48.6
				c-1.8,0-2.9-1-2.9-3.5V25.5c0-2.6,1.1-3.5,2.9-3.5c1.8,0,2.9,1,2.9,3.5v19.6C761.5,47.6,760.3,48.6,758.5,48.6 M758.5,53.8
				c5.5,0,8.6-3.3,8.6-9V25.8c0-5.7-3-9-8.6-9c-5.5,0-8.6,3.3-8.6,9v18.9C750,50.5,753,53.8,758.5,53.8 M731.4,53.2h14.9v-5.1H737
				V17.3h-5.6V53.2z M714.7,41.3l2.5-17.8h0.1l2.5,17.8H714.7z M707.8,53.2h5.2l1-7h6.4v-0.1l1,7.1h5.6l-5.9-35.9h-7.6L707.8,53.2z
				 M695.8,53.2h5.6V22.4h5.9v-5.1h-17.5v5.1h5.9V53.2z M677.1,41.3l2.5-17.8h0.1l2.5,17.8H677.1z M670.2,53.2h5.2l1-7h6.4v-0.1
				l1,7.1h5.6l-5.9-35.9H676L670.2,53.2z M658.4,53.8c5.4,0,8.3-3.3,8.3-8.8v-5.1h-5.3v5.5c0,2.4-1.1,3.3-2.8,3.3
				c-1.7,0-2.8-0.9-2.8-3.3V25.3c0-2.4,1.1-3.3,2.8-3.3c1.7,0,2.8,1,2.8,3.3v4.2h5.3v-3.8c0-5.5-2.9-8.8-8.3-8.8
				c-5.4,0-8.3,3.3-8.3,8.8v19.3C650.1,50.5,653,53.8,658.4,53.8"
				/>
				<path
					className="st9"
					d="M758.8,164.4c0-10.8-6.9-16-18.3-20.1c-8-2.9-11.7-6.5-11.7-12c0-5.1,4.3-10.1,12.2-10.1
				c4.9,0,9.9,1.9,12.3,3.8l1.5-5.8c-2.6-1.7-7.7-3.7-13.6-3.7c-12.9,0-18.9,8-18.9,16.2c0,8.8,5.4,14.3,16.8,18.4
				c8.9,3.3,12.9,7,12.9,13.8c0,7-5.7,11.7-13.6,11.7c-7.4,0-13.5-2.6-18.2-6.4l0.9,7.4c3.6,2.5,9.6,4.9,17,4.9
				C750.8,182.5,758.8,175.4,758.8,164.4 M703.4,183v-65.9h-6.8v34.6c0,5.5,0,11.3,0.2,17.1l-0.2,0.1c-4.3-5.4-10.3-12.4-14.6-17.4
				l-30.1-34.4h-4.8V182h6.8v-35.8c0-5.9,0-11.9-0.2-17.9l0.2-0.1c4.5,5.5,10.5,12.7,15.8,18.7l31.7,36.1H703.4z M627,117.1h-6.8V182
				h6.8V117.1z M595.8,149.7c0,16.7-12.9,27.6-27,27.6c-14.2,0-27-10.9-27-27.6s12.8-27.6,27-27.6C582.9,122.1,595.8,133,595.8,149.7
				 M602.8,149.5c0-20-16.1-33.4-34-33.4c-17.9,0-34,13.4-34,33.4c0,20.1,16.1,33.7,34,33.7C586.7,183.1,602.8,169.6,602.8,149.5
				 M520.5,182l-5.4-64.9h-4.4l-17.7,36.3c-2.9,6.1-5.9,12.3-8.3,18.2h-0.2c-2.5-6-5.7-12.6-8.4-18.2l-17.8-36.3h-4.5l-5.2,64.9h6.2
				l2.6-33.6c0.4-4.9,0.8-11.3,1-16.8h0.2c2.4,5.7,5.5,12.2,7.9,17.2l16.4,33.7h2.5l16.4-33.7c2.5-5.3,5.8-11.9,8-17.3h0.2
				c0.2,5.5,0.7,11.4,1.1,16.9l2.6,33.6H520.5z M431.1,95.5c0-1.1-2.4-3.6-3.7-3.6c-0.6,0-1.7,0.6-2.2,1.4
				c-3.5,4.9-8.8,10.4-13.6,14.1l4.2,1.8c5.4-3.4,10.3-7.3,14.2-11.9C430.5,96.7,431.1,96,431.1,95.5 M437.9,182v-5.9h-30.6v-23.8
				h22.2v-5.9h-22.2V123h27v-5.9h-33.9V182H437.9z M387.6,123v-5.9h-51.4v5.9h22.3v59h6.8v-59H387.6z"
				/>
			</g>
		</g>
	</svg>
);

export const HistoireEtMemoire = props => (
	<svg
		width={props.width || 40}
		version="1.1"
		id="Calque_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1280 330"
		style={{ enableBackground: "new 0 0 1280 330" }}
		xmlSpace="preserve"
	>
		<style
			type="text/css"
			dangerouslySetInnerHTML={{
				__html:
					"\n\t.st0{clip-path:url(#SVGID_2_);}\n\t.st1{opacity:0.64;clip-path:url(#SVGID_4_);}\n\t.st2{filter:url(#Adobe_OpacityMaskFilter);}\n\t.st3{clip-path:url(#SVGID_6_);fill:url(#SVGID_8_);}\n\t.st4{clip-path:url(#SVGID_6_);mask:url(#SVGID_7_);}\n\t.st5{clip-path:url(#SVGID_10_);fill:#FFFFFF;}\n\t.st6{clip-path:url(#SVGID_2_);fill:none;stroke:#FFFFFF;stroke-width:2.835;}\n\t.st7{clip-path:url(#SVGID_2_);fill:#FFFFFF;}\n\t.st8{clip-path:url(#SVGID_12_);fill:#C3001F;}\n\t.st9{clip-path:url(#SVGID_12_);fill:#2A292A;}\n\t.st10{opacity:0.42;clip-path:url(#SVGID_12_);}\n\t.st11{clip-path:url(#SVGID_14_);fill:#C3001F;}\n"
			}}
		/>
		<g>
			<defs>
				<rect id="SVGID_1_" y="0.3" width={1280} height="328.7" />
			</defs>
			<clipPath id="SVGID_2_">
				<use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
			</clipPath>
			<g className="st0">
				<defs>
					<rect id="SVGID_3_" y="0.3" width={1280} height="328.7" />
				</defs>
				<clipPath id="SVGID_4_">
					<use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
				</clipPath>
				<g className="st1">
					<g>
						<defs>
							<rect
								id="SVGID_5_"
								x={-12}
								y="67.4"
								width="1303.9"
								height="216.5"
							/>
						</defs>
						<clipPath id="SVGID_6_">
							<use xlinkHref="#SVGID_5_" style={{ overflow: "visible" }} />
						</clipPath>
						<defs>
							<filter
								id="Adobe_OpacityMaskFilter"
								filterUnits="userSpaceOnUse"
								x={-12}
								y="67.4"
								width="1303.9"
								height="216.5"
							>
								<feFlood
									style={{ floodColor: "white", floodOpacity: 1 }}
									result="back"
								/>
								<feBlend in="SourceGraphic" in2="back" mode="normal" />
							</filter>
						</defs>
						<mask
							maskUnits="userSpaceOnUse"
							x={-12}
							y="67.4"
							width="1303.9"
							height="216.5"
							id="SVGID_7_"
						>
							<g className="st2">
								<linearGradient
									id="SVGID_8_"
									gradientUnits="userSpaceOnUse"
									x1="0.7383"
									y1="656.9728"
									x2="2.0675"
									y2="656.9728"
									gradientTransform="matrix(-392.5814 0 0 392.5814 708.6666 -257739.6562)"
								>
									<stop offset={0} style={{ stopColor: "#FFFFFF" }} />
									<stop offset={1} style={{ stopColor: "#000000" }} />
								</linearGradient>
								<rect
									x={-12}
									y="67.4"
									className="st3"
									width="1303.9"
									height="216.5"
								/>
							</g>
						</mask>
						<g className="st4">
							<g>
								<defs>
									<rect
										id="SVGID_9_"
										x={-12}
										y="67.4"
										width="1303.9"
										height="216.5"
									/>
								</defs>
								<clipPath id="SVGID_10_">
									<use xlinkHref="#SVGID_9_" style={{ overflow: "visible" }} />
								</clipPath>
								<rect y="76.7" className="st5" width={1280} height="205.9" />
							</g>
						</g>
					</g>
				</g>
			</g>
			<polygon
				className="st6"
				points="804.1,255 240.3,255 239.3,32.5 973.8,32.5 	"
			/>
			<rect x="660.6" y="0.3" className="st7" width="381.8" height="76.4" />
			<g className="st0">
				<defs>
					<rect id="SVGID_11_" y="0.3" width={1280} height="328.7" />
				</defs>
				<clipPath id="SVGID_12_">
					<use xlinkHref="#SVGID_11_" style={{ overflow: "visible" }} />
				</clipPath>
				<path
					className="st8"
					d="M947.2,62H965V56h-11.3V43.2h9v-5.9h-9v-11H965v-5.9h-17.8V62z M931,62.6c6.4,0,9.7-3.8,9.7-10.5V20.4h-6.3
			v32.2c0,3-1.2,4-3.3,4c-2.1,0-3.3-1.1-3.3-4V20.4h-6.5v31.7C921.3,58.8,924.6,62.6,931,62.6 M905.7,62.6c6.4,0,9.7-3.8,9.7-10.5
			V38.5H906v5.9h3.3v8.1c0,3-1.3,4-3.4,4c-2.1,0-3.4-1.1-3.4-4V29.8c0-3,1.3-4.1,3.4-4.1c2.1,0,3.4,1.1,3.4,4.1v4h6.2v-3.6
			c0-6.7-3.3-10.5-9.7-10.5s-9.7,3.8-9.7,10.5v21.9C896,58.8,899.3,62.6,905.7,62.6 M880.4,56.6c-2.1,0-3.4-1.1-3.4-4.1V29.8
			c0-3,1.3-4.1,3.4-4.1c2.1,0,3.4,1.1,3.4,4.1v22.7C883.7,55.5,882.4,56.6,880.4,56.6 M880.4,62.6c6.4,0,9.9-3.8,9.9-10.5V30.2
			c0-6.7-3.5-10.5-9.9-10.5c-6.4,0-9.9,3.8-9.9,10.5v21.9C870.4,58.8,873.9,62.6,880.4,62.6 M848.9,62h17.3V56h-10.8V20.4h-6.5V62z
			 M829.6,48.2l2.9-20.6h0.1l2.9,20.6H829.6z M821.6,62h6.1l1.2-8.1h7.4v-0.1l1.2,8.3h6.5l-6.8-41.6h-8.9L821.6,62z M807.7,62h6.5
			V26.3h6.8v-5.9h-20.2v5.9h6.8V62z M786,48.2l2.9-20.6h0.1l2.9,20.6H786z M778,62h6.1l1.2-8.1h7.4v-0.1l1.2,8.3h6.5l-6.8-41.6h-8.9
			L778,62z M764.4,62.6c6.3,0,9.6-3.8,9.6-10.2v-5.9h-6.2v6.4c0,2.7-1.3,3.8-3.3,3.8c-2,0-3.3-1.1-3.3-3.8V29.6
			c0-2.7,1.3-3.9,3.3-3.9c2,0,3.3,1.1,3.3,3.9v4.8h6.2V30c0-6.4-3.3-10.2-9.6-10.2c-6.3,0-9.6,3.8-9.6,10.2v22.4
			C754.8,58.8,758.1,62.6,764.4,62.6"
				/>
				<path
					className="st9"
					d="M1203.8,214.5v-6.8h-35.4v-27.6h25.7v-6.8h-25.7v-27.1h31.3v-6.8h-39.2v75.1H1203.8z M1129.8,159.9
			c0,8.6-6.9,13.5-15.1,13.5H1103v-27.1h11.5C1122.8,146.3,1129.8,151.4,1129.8,159.9 M1149.5,214.5l-26.4-35.6
			c8.5-2.4,14.7-9.4,14.7-19.2c0-12.8-10.6-20.4-22-20.4h-20.7v75.1h7.9v-34.4h11.8l25,34.4H1149.5z M1071.8,139.4h-7.9v75.1h7.9
			V139.4z M1035.7,177.1c0,19.3-15,31.9-31.3,31.9c-16.5,0-31.3-12.6-31.3-31.9s14.8-31.9,31.3-31.9
			C1020.8,145.2,1035.7,157.8,1035.7,177.1 M1043.8,176.8c0-23.2-18.6-38.7-39.3-38.7c-20.7,0-39.3,15.5-39.3,38.7
			c0,23.3,18.6,39,39.3,39C1025.1,215.8,1043.8,200.2,1043.8,176.8 M948.5,214.5l-6.2-75.1h-5.1l-20.5,42
			c-3.4,7.1-6.8,14.2-9.7,21.1h-0.2c-2.9-6.9-6.6-14.6-9.8-21.1l-20.6-42h-5.2l-6,75.1h7.2l3.1-39c0.5-5.7,0.9-13.1,1.2-19.4h0.2
			c2.8,6.6,6.4,14.1,9.2,19.9l19,39h2.9l19-39c2.9-6.1,6.7-13.8,9.3-20h0.2c0.2,6.4,0.8,13.2,1.3,19.5l3.1,39H948.5z M844.9,114.3
			c0-1.3-2.8-4.1-4.2-4.1c-0.7,0-2,0.7-2.6,1.6c-4,5.7-10.2,12-15.8,16.4l4.8,2.1c6.2-3.9,11.9-8.5,16.5-13.8
			C844.3,115.7,844.9,114.9,844.9,114.3 M852.8,214.5v-6.8h-35.4v-27.6H843v-6.8h-25.7v-27.1h31.3v-6.8h-39.2v75.1H852.8z
			 M789.7,214.5l-6.2-75.1h-5.1l-20.5,42c-3.4,7.1-6.8,14.2-9.7,21.1H748c-2.9-6.9-6.6-14.6-9.8-21.1l-20.6-42h-5.2l-6,75.1h7.2
			l3.1-39c0.5-5.7,0.9-13.1,1.2-19.4h0.2c2.8,6.6,6.4,14.1,9.2,19.9l19,39h2.9l19-39c2.9-6.1,6.7-13.8,9.3-20h0.2
			c0.2,6.4,0.8,13.2,1.3,19.5l3.1,39H789.7z M636.6,201.1c-5.4,5.1-11.9,8.1-19,8.1c-8.9,0-15.4-6-15.4-13.4
			c0-7.7,5.3-12.5,12.6-16.5L636.6,201.1z M629.8,155.8c0,5.2-3.8,9.8-13.3,15.1c-6.4-6.4-9.7-10.4-9.7-15.4
			c0-5.9,4.6-10.9,11.1-10.9C625.1,144.5,629.8,149.6,629.8,155.8 M660,214.5l-13.8-13.8c3.5-4.7,6.5-10.6,8.6-17.2l-5.1-5.2
			c-2,6.6-4.8,12.6-8.5,17.4l-19.9-20c10.8-6.2,15.5-12.5,15.5-20.1c0-11-9.2-17.5-19.1-17.5c-9.4,0-18.3,6.8-18.3,17.7
			c0,6.1,3.3,11.5,10.2,18.6c-9.4,5.4-15.2,11.7-15.2,21.8c0,10.5,8.8,19.7,22.6,19.7c9.5,0,17.7-3.5,24.3-9.8l8.5,8.5H660z
			 M549.1,214.5v-6.8h-35.4v-27.6h25.7v-6.8h-25.7v-27.1H545v-6.8h-39.2v75.1H549.1z M475.2,159.9c0,8.6-6.9,13.5-15.1,13.5h-11.8
			v-27.1h11.5C468.1,146.3,475.2,151.4,475.2,159.9 M494.8,214.5L468.5,179c8.5-2.4,14.7-9.4,14.7-19.2c0-12.8-10.6-20.4-22-20.4
			h-20.7v75.1h7.9v-34.4h11.8l25,34.4H494.8z M417.1,139.4h-7.9v75.1h7.9V139.4z M381.1,177.1c0,19.3-15,31.9-31.3,31.9
			c-16.5,0-31.3-12.6-31.3-31.9s14.8-31.9,31.3-31.9C366.1,145.2,381.1,157.8,381.1,177.1 M389.1,176.8c0-23.2-18.6-38.7-39.3-38.7
			c-20.7,0-39.3,15.5-39.3,38.7c0,23.3,18.6,39,39.3,39C370.5,215.8,389.1,200.2,389.1,176.8 M303.9,146.2v-6.8h-59.6v6.8h25.8v68.3
			h7.9v-68.3H303.9z M235,194.1c0-12.5-8-18.5-21.2-23.3c-9.3-3.4-13.5-7.5-13.5-13.9c0-5.9,4.9-11.7,14.1-11.7
			c5.7,0,11.4,2.2,14.2,4.4l1.8-6.7c-3.1-2-8.9-4.2-15.8-4.2c-15,0-21.9,9.3-21.9,18.7c0,10.2,6.2,16.6,19.4,21.3
			c10.4,3.8,15,8.1,15,16c0,8.1-6.6,13.5-15.8,13.5c-8.6,0-15.7-3.1-21.1-7.4l1.1,8.6c4.1,2.9,11.1,5.7,19.7,5.7
			C225.7,215.1,235,206.9,235,194.1 M169.6,139.4h-7.9v75.1h7.9V139.4z M138.4,214.5v-75.1h-7.9v33.9H87.4v-33.9h-7.9v75.1h7.9
			v-34.4h43.1v34.4H138.4z"
				/>
				<g className="st10">
					<g>
						<defs>
							<rect
								id="SVGID_13_"
								x="145.3"
								y="201.8"
								width="600.8"
								height="139.2"
							/>
						</defs>
						<clipPath id="SVGID_14_">
							<use xlinkHref="#SVGID_13_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st11"
							points="625.9,213.8 146.6,213.8 256.9,329 736.8,329 				"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
);

export const ReformeEtReveil = props => (
	<svg
		width={props.width || 40}
		version="1.1"
		id="Calque_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1106 284"
		style={{ enableBackground: "new 0 0 1106 284" }}
		xmlSpace="preserve"
	>
		<style
			type="text/css"
			dangerouslySetInnerHTML={{
				__html:
					"\n\t.st0{clip-path:url(#SVGID_2_);}\n\t.st1{opacity:0.33;clip-path:url(#SVGID_4_);}\n\t.st2{clip-path:url(#SVGID_6_);fill:#FFFFFF;}\n\t.st3{opacity:0.64;clip-path:url(#SVGID_4_);}\n\t.st4{clip-path:url(#SVGID_8_);fill:#192D7E;}\n\t.st5{opacity:0.56;clip-path:url(#SVGID_4_);}\n\t.st6{clip-path:url(#SVGID_10_);fill:none;stroke:#FFFFFF;stroke-width:2.835;}\n\t.st7{clip-path:url(#SVGID_2_);fill:#FFFFFF;}\n\t.st8{clip-path:url(#SVGID_12_);fill:#2B4998;}\n\t.st9{clip-path:url(#SVGID_12_);fill:#FFFFFF;}\n"
			}}
		/>
		<g>
			<defs>
				<rect id="SVGID_1_" x="0.2" y="0.4" width="1103.7" height="283.6" />
			</defs>
			<clipPath id="SVGID_2_">
				<use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
			</clipPath>
			<g className="st0">
				<defs>
					<rect id="SVGID_3_" x="0.2" y="0.4" width="1103.7" height="283.6" />
				</defs>
				<clipPath id="SVGID_4_">
					<use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
				</clipPath>
				<g className="st1">
					<g>
						<defs>
							<rect
								id="SVGID_5_"
								x="-10.1"
								y="58.2"
								width="1124.3"
								height="186.7"
							/>
						</defs>
						<clipPath id="SVGID_6_">
							<use xlinkHref="#SVGID_5_" style={{ overflow: "visible" }} />
						</clipPath>
						<rect
							x="0.2"
							y="66.2"
							className="st2"
							width="1103.7"
							height="177.5"
						/>
					</g>
				</g>
				<g className="st3">
					<g>
						<defs>
							<rect
								id="SVGID_7_"
								x="125.5"
								y="174.3"
								width={518}
								height={120}
							/>
						</defs>
						<clipPath id="SVGID_8_">
							<use xlinkHref="#SVGID_7_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st4"
							points="539.9,184.6 126.6,184.6 221.7,284 635.5,284 				"
						/>
					</g>
				</g>
				<g className="st5">
					<g>
						<defs>
							<rect
								id="SVGID_9_"
								x="203.8"
								y="7.3"
								width="647.4"
								height="224.7"
							/>
						</defs>
						<clipPath id="SVGID_10_">
							<use xlinkHref="#SVGID_9_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st6"
							points="693.6,220 207.4,220 206.6,28.2 839.9,28.2 				"
						/>
					</g>
				</g>
			</g>
			<rect x="569.9" y="0.4" className="st7" width="329.2" height="65.8" />
			<g className="st0">
				<defs>
					<rect id="SVGID_11_" x="0.2" y="0.4" width="1103.7" height="283.6" />
				</defs>
				<clipPath id="SVGID_12_">
					<use xlinkHref="#SVGID_11_" style={{ overflow: "visible" }} />
				</clipPath>
				<path
					className="st8"
					d="M808.9,53.6h15.4v-5.1h-9.7v-11h7.7v-5.1h-7.7v-9.5h9.7v-5.1h-15.4V53.6z M794.9,54.1c5.5,0,8.4-3.3,8.4-9
			V17.7h-5.4v27.8c0,2.6-1.1,3.5-2.9,3.5c-1.8,0-2.9-0.9-2.9-3.5V17.7h-5.6v27.4C786.5,50.8,789.4,54.1,794.9,54.1 M773.2,54.1
			c5.5,0,8.4-3.3,8.4-9V33.3h-8.1v5.1h2.8v7c0,2.6-1.1,3.5-2.9,3.5c-1.8,0-2.9-0.9-2.9-3.5V25.8c0-2.6,1.1-3.5,2.9-3.5
			c1.8,0,2.9,1,2.9,3.5v3.4h5.3v-3.1c0-5.7-2.9-9-8.4-9c-5.5,0-8.4,3.3-8.4,9v18.9C764.8,50.8,767.6,54.1,773.2,54.1 M751.3,48.9
			c-1.8,0-2.9-1-2.9-3.5V25.8c0-2.6,1.1-3.5,2.9-3.5c1.8,0,2.9,1,2.9,3.5v19.6C754.2,48,753.1,48.9,751.3,48.9 M751.3,54.1
			c5.5,0,8.6-3.3,8.6-9V26.2c0-5.7-3-9-8.6-9c-5.5,0-8.6,3.3-8.6,9v18.9C742.7,50.8,745.7,54.1,751.3,54.1 M724.2,53.6h14.9v-5.1
			h-9.3V17.7h-5.6V53.6z M707.5,41.7l2.5-17.7h0.1l2.5,17.7H707.5z M700.6,53.6h5.2l1-7h6.4v-0.1l1,7.1h5.6l-5.8-35.9h-7.6
			L700.6,53.6z M688.6,53.6h5.6V22.8h5.9v-5.1h-17.4v5.1h5.9V53.6z M669.9,41.7l2.5-17.7h0.1l2.5,17.7H669.9z M663,53.6h5.2l1-7h6.4
			v-0.1l1,7.1h5.6l-5.8-35.9h-7.6L663,53.6z M651.3,54.1c5.4,0,8.3-3.3,8.3-8.8v-5.1h-5.3v5.5c0,2.4-1.1,3.3-2.8,3.3
			c-1.7,0-2.8-0.9-2.8-3.3v-20c0-2.4,1.1-3.3,2.8-3.3c1.7,0,2.8,1,2.8,3.3v4.2h5.3V26c0-5.5-2.9-8.8-8.3-8.8c-5.4,0-8.3,3.3-8.3,8.8
			v19.3C643,50.8,645.8,54.1,651.3,54.1"
				/>
				<path
					className="st9"
					d="M973.1,182.1v-5.9h-30.2v-58.9h-6.8v64.8H973.1z M916,117.4h-6.8v64.8h6.8V117.4z M895.4,182.1v-5.9h-30.6
			v-23.8H887v-5.9h-22.1v-23.4h27v-5.9h-33.8v64.8H895.4z M843.3,117.4h-6.5l-13.7,34.9c-2.1,5.5-4.6,11.9-6.4,17.6h-0.2
			c-1.8-5.7-4.3-12.3-6.3-17.6l-13.7-34.9h-7.2l25.9,65.9h2.2L843.3,117.4z M774.1,95.7c0-1.1-2.4-3.6-3.7-3.6
			c-0.6,0-1.7,0.6-2.2,1.4c-3.5,4.9-8.8,10.4-13.6,14.1l4.2,1.8c5.4-3.4,10.3-7.3,14.2-11.9C773.6,97,774.1,96.2,774.1,95.7
			 M780.9,182.1v-5.9h-30.6v-23.8h22.1v-5.9h-22.1v-23.4h27v-5.9h-33.8v64.8H780.9z M717.1,135c0,7.4-6,11.7-13,11.7H694v-23.4h9.9
			C711,123.4,717.1,127.7,717.1,135 M734.1,182.1l-22.7-30.7c7.3-2,12.7-8.1,12.7-16.5c0-11.1-9.1-17.6-19-17.6h-17.9v64.8h6.8
			v-29.6h10.2l21.5,29.6H734.1z M625,170.6c-4.7,4.4-10.3,7-16.3,7c-7.7,0-13.3-5.2-13.3-11.6c0-6.6,4.6-10.8,10.9-14.2L625,170.6z
			 M619.1,131.5c0,4.5-3.2,8.4-11.5,13c-5.5-5.5-8.3-8.9-8.3-13.3c0-5.1,4-9.4,9.5-9.4C615,121.7,619.1,126.2,619.1,131.5
			 M645.2,182.1l-11.9-11.9c3-4.1,5.6-9.1,7.4-14.8l-4.4-4.5c-1.7,5.7-4.2,10.9-7.3,15l-17.2-17.3c9.3-5.4,13.4-10.8,13.4-17.4
			c0-9.4-7.9-15.1-16.4-15.1c-8.1,0-15.7,5.9-15.7,15.2c0,5.3,2.8,9.9,8.8,16c-8.1,4.7-13.1,10-13.1,18.8c0,9,7.6,17,19.5,17
			c8.2,0,15.2-3,20.9-8.4l7.3,7.3H645.2z M549.5,182.1v-5.9H519v-23.8h22.1v-5.9H519v-23.4h27v-5.9h-33.8v64.8H549.5z M495.1,182.1
			l-5.4-64.8h-4.4l-17.7,36.2c-2.9,6.1-5.9,12.3-8.3,18.2h-0.2c-2.5-6-5.7-12.6-8.4-18.2L433,117.4h-4.5l-5.2,64.8h6.2l2.6-33.6
			c0.4-4.9,0.8-11.3,1-16.8h0.2c2.4,5.7,5.5,12.2,7.9,17.2l16.3,33.6h2.5l16.3-33.6c2.5-5.3,5.8-11.9,8-17.3h0.2
			c0.2,5.5,0.7,11.4,1.1,16.9l2.6,33.6H495.1z M399,135c0,7.4-6,11.7-13,11.7h-10.2v-23.4h9.9C392.9,123.4,399,127.7,399,135
			 M415.9,182.1l-22.7-30.7c7.3-2,12.7-8.1,12.7-16.5c0-11.1-9.1-17.6-19-17.6H369v64.8h6.8v-29.6H386l21.5,29.6H415.9z
			 M345.8,149.9c0,16.7-12.9,27.5-27,27.5c-14.2,0-27-10.9-27-27.5s12.8-27.5,27-27.5C332.9,122.3,345.8,133.2,345.8,149.9
			 M352.7,149.7c0-20-16-33.4-33.9-33.4s-33.9,13.4-33.9,33.4c0,20.1,16,33.6,33.9,33.6S352.7,169.8,352.7,149.7 M274.8,123.3v-5.9
			h-34.4v64.8h6.8v-29.6h23.6v-5.9h-23.6v-23.4H274.8z M220.8,95.7c0-1.1-2.4-3.6-3.7-3.6c-0.6,0-1.7,0.6-2.2,1.4
			c-3.5,4.9-8.8,10.4-13.6,14.1l4.2,1.8c5.4-3.4,10.3-7.3,14.2-11.9C220.3,97,220.8,96.2,220.8,95.7 M227.6,182.1v-5.9H197v-23.8
			h22.1v-5.9H197v-23.4h27v-5.9h-33.8v64.8H227.6z M163.8,135c0,7.4-6,11.7-13,11.7h-10.2v-23.4h9.9
			C157.7,123.4,163.8,127.7,163.8,135 M180.8,182.1l-22.7-30.7c7.3-2,12.7-8.1,12.7-16.5c0-11.1-9.1-17.6-19-17.6h-17.9v64.8h6.8
			v-29.6h10.2l21.5,29.6H180.8z"
				/>
			</g>
		</g>
	</svg>
);

const ContactLogo = props => (
	<svg
		width={props.width || 40}
		version="1.1"
		id="Calque_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 1106 257"
		style={{ enableBackground: "new 0 0 1106 257" }}
		xmlSpace="preserve"
	>
		<style
			type="text/css"
			dangerouslySetInnerHTML={{
				__html:
					"\n\t.st0{clip-path:url(#SVGID_2_);}\n\t.st1{opacity:0.33;clip-path:url(#SVGID_4_);}\n\t.st2{clip-path:url(#SVGID_6_);fill:#FFFFFF;}\n\t.st3{opacity:0.64;clip-path:url(#SVGID_4_);}\n\t.st4{clip-path:url(#SVGID_8_);fill:#192D7E;}\n\t.st5{opacity:0.56;clip-path:url(#SVGID_4_);}\n\t.st6{clip-path:url(#SVGID_10_);fill:none;stroke:#FFFFFF;stroke-width:2.835;}\n\t.st7{clip-path:url(#SVGID_4_);fill:#FFFFFF;}\n"
			}}
		/>
		<g>
			<defs>
				<rect id="SVGID_1_" x="0.2" width="1101.1" height="256.8" />
			</defs>
			<clipPath id="SVGID_2_">
				<use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }} />
			</clipPath>
			<g className="st0">
				<defs>
					<rect id="SVGID_3_" x="0.2" width="1101.1" height="256.8" />
				</defs>
				<clipPath id="SVGID_4_">
					<use xlinkHref="#SVGID_3_" style={{ overflow: "visible" }} />
				</clipPath>
				<g className="st1">
					<g>
						<defs>
							<rect
								id="SVGID_5_"
								x="-10.1"
								y="31.6"
								width="1121.7"
								height="186.3"
							/>
						</defs>
						<clipPath id="SVGID_6_">
							<use xlinkHref="#SVGID_5_" style={{ overflow: "visible" }} />
						</clipPath>
						<rect
							x="0.2"
							y="39.6"
							className="st2"
							width="1101.1"
							height="177.1"
						/>
					</g>
				</g>
				<g className="st3">
					<g>
						<defs>
							<rect
								id="SVGID_7_"
								x="125.2"
								y="147.4"
								width="516.8"
								height="119.7"
							/>
						</defs>
						<clipPath id="SVGID_8_">
							<use xlinkHref="#SVGID_7_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st4"
							points="538.7,157.7 126.4,157.7 221.2,256.8 634,256.8 				"
						/>
					</g>
				</g>
				<g className="st5">
					<g>
						<defs>
							<rect
								id="SVGID_9_"
								x="203.3"
								y="-19.2"
								width="645.9"
								height="224.2"
							/>
						</defs>
						<clipPath id="SVGID_10_">
							<use xlinkHref="#SVGID_9_" style={{ overflow: "visible" }} />
						</clipPath>
						<polygon
							className="st6"
							points="692,193 207,193 206.1,1.6 838,1.6 				"
						/>
					</g>
				</g>
				<path
					className="st7"
					d="M835.8,101.9v-7H786v7h20.8v55.8h8.1v-55.8H835.8z M776.5,98.5c-4-2.3-10.2-4.1-18.4-4.1
			c-16,0-32.9,11.5-32.9,32c0,20.3,16.7,32,33,32c7.8,0,12.7-1.2,16.4-3.2l0.8-7.2c-4,2.4-9.2,3.8-16.4,3.8
			c-13.5,0-26.1-9.1-26.1-25.3s12.8-25.3,26.1-25.3c6.5,0,12.7,2.1,16,4.2L776.5,98.5z M698.6,134.3h-20l6.9-17.3
			c1-2.5,2.3-5.7,3-8.7h0.2c0.9,3,2,6.2,3,8.7L698.6,134.3z M716.5,157.7l-26.3-63.6h-3L661,157.7h8l6.9-16.9h25.5l7,16.9H716.5z
			 M660.3,101.9v-7h-49.8v7h20.8v55.8h8.1v-55.8H660.3z M598.5,158.9V95h-8.1v31.6c0,4.9,0,10.4,0.2,15.6l-0.2,0.1
			c-4-5-9.7-11.5-13.7-15.9l-28-31.4h-5.6v62.7h8.1v-33c0-5.2,0-10.8-0.2-16.5l0.2-0.1c4.3,5.2,9.9,11.8,14.8,17.3l30.1,33.5H598.5z
			 M519.4,126.4c0,15.8-12.4,25.6-24.9,25.6c-12.7,0-24.9-9.7-24.9-25.6s12.2-25.5,24.9-25.5C507,101,519.4,110.6,519.4,126.4
			 M527.6,126.2c0-19.8-16-32.5-33-32.5c-17,0-33,12.7-33,32.5c0,20,16,32.5,33,32.5C511.5,158.8,527.6,146.2,527.6,126.2
			 M450.5,98.5c-4-2.3-10.2-4.1-18.4-4.1c-16,0-32.9,11.5-32.9,32c0,20.3,16.7,32,33,32c7.8,0,12.7-1.2,16.4-3.2l0.8-7.2
			c-4,2.4-9.2,3.8-16.4,3.8c-13.5,0-26.1-9.1-26.1-25.3s12.8-25.3,26.1-25.3c6.5,0,12.7,2.1,16,4.2L450.5,98.5z"
				/>
			</g>
		</g>
	</svg>
);

class SlideTemplate2 extends React.Component {
	wallpaper() {
		return (
			<figure
				className="figureReactBackgroundSlider"
				style={{
					backgroundImage:
						"url(" +
						`${tools.lienCalvinEdition}/header/${this.props.slide.image}` +
						")",
					maxHeight: "900px",
					backgroundSize: "auto",
					marginTop: "90px"
				}}
			/>
		);
	}

	logo(size) {
		return (
			<>
				{this.props.slide.logo === 1 && (
					<ReformeEtReveil width={size} color="#fff" />
				)}
				{this.props.slide.logo === 2 && (
					<LogoTemoins width={size} color="#fff" />
				)}
				{this.props.slide.logo === 3 && (
					<HistoireEtMemoire width={size} color="#fff" />
				)}
				{this.props.slide.logo === 4 && (
					<ContactLogo width={size} color="#fff" />
				)}
			</>
		);
	}

	render() {
		const data = this.props.slide;
		const { t } = this.props;

		return (
			<>
				<section style={{ height: "680px" }}>
					<div id="ReactBackgroundSlider">{this.wallpaper()}</div>
					<div className="container-fluid no-gutters">
						<div className="row no-gutters" style={{ marginTop: "50px" }}>
							<div className="col-xl-2 col-lg-1 no-gutters" style={{}}></div>
							<div className="col-xl-8 col-lg-12">
								<div className="text-center mb-5 pt-5">
									<div className="text-center" style={{ paddingTop: "180px" }}>
										<div style={{ position: "relative" }}>
											<div className="d-block d-md-none">
												<Link to="/">
													<div>
														{this.logo("340px")}
														{/* <LogoTemoins width={"270px"} color="#fff" /> */}
													</div>
												</Link>
											</div>

											<div className="d-none d-md-block no-gutters">
												<Link to="/">
													<div>
														{this.logo("600px")}
														{/* <LogoTemoins width={"600px"} color="#fff" /> */}
													</div>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-lg-1 no-gutters" style={{}}></div>
						</div>
					</div>
				</section>
			</>
		);
	}
}
export default withTranslation()(SlideTemplate2);
