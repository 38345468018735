import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { DATA } from "../Livre/Data";
import { tools } from "@mitim/react-mitim";
import BanniereCatalogue from "./BanniereCatalogue";

const Livre = props => (
	<div className="col-md-4">
		<div className="livreBloc">
			<div className="bloc">
				<Link to={`/livre/${props.data.id}/${props.data.titre}`}>
					<img
						src={`${tools.lienCalvinEdition}/livres/${props.data.couverture}`}
						alt=""
						className="img-fluid"
					/>
					<div className="ombre"></div>
					<div className="texteLien">
						<span className="">VOIR PLUS</span>
					</div>
				</Link>
			</div>
			<div className={"texteBloc rouge " + props.data.couleurBloc}>
				<div className="titre">{props.data.titre}</div>
				<div className="sousTitre">{props.data.auteur}</div>
			</div>
		</div>
	</div>
);

class HistoireEtMemoire extends Page {
	render() {
		const { t } = this.props;

		return (
			<>
				<BanniereCatalogue
					titre={<>histoire et mémoire</>}
					paragraphe={
						<>
							À la croisée de l’Histoire et de la mémoire, nous rééditons dans
							cette collection des livres qui ont marqué l’historiographie
							protestante. Mais nous éditons aussi des travaux issus de la
							recherche la plus récente, ainsi que la traduction d’ouvrages
							majeurs indisponibles en français.
						</>
					}
					couleur="rouge"
					liens={{
						lien1: {
							texte: "Réforme et réveil",
							to: "/catalogue/reforme-et-reveil"
						},
						lien2: {
							texte: "Témoins",
							to: "/catalogue/temoins"
						}
					}}
				/>
				<div
					className="container-fluid pt-5 pb-5 bg-white"
					style={{ minHeight: "400px" }}
				>
					<div className="container">
						<div className="row pt-5 pb-5 listeLivre">
							<div className="col-md-2"></div>

							<Livre data={DATA[9]} />
							<Livre data={DATA[5]} />

							<div className="col-md-2"></div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(HistoireEtMemoire);
