import React from "react";
import ToggleButton from "./ToggleButton";
import Dropdown from "./Dropdown";
import { Link, NavLink } from "react-router-dom";
import Backdrop from "./Backdrop";
import SvgIcon from "@material-ui/core/SvgIcon";
import "./Menu.scss";
// import auth from "../Login/Auth";
import { auth } from "@mitim/react-mitim";

const LogoCalvinEdition2 = props => (
	<svg
		width={props.width || 40}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 1279.93 296.13"
	>
		<defs>
			<style
				dangerouslySetInnerHTML={{
					__html:
						".logoCalvinEdition1{fill:none;}.logoCalvinEdition2{clip-path:url(#cplogoCalvinEdition);}.logoCalvinEdition3{fill:#2a292a;}"
				}}
			/>
			<clipPath id="cplogoCalvinEdition" transform="translate(-0.07 -358.02)">
				<rect
					className="logoCalvinEdition1"
					x="0.07"
					y="358.02"
					width="1279.93"
					height="296.13"
				/>
			</clipPath>
		</defs>
		<g className="logoCalvinEdition2">
			<path
				className="logoCalvinEdition3"
				d="M10,402.15a11,11,0,0,0,1.07,4.93A8.23,8.23,0,0,0,14,410.44a7.34,7.34,0,0,0,4.09,1.21,6.1,6.1,0,0,0,3.62-1,8.77,8.77,0,0,0,2.4-2.5,38.09,38.09,0,0,0,2.12-3.86,21.75,21.75,0,0,1,2.53-4.41,3.72,3.72,0,0,1,3.06-1.46,4.48,4.48,0,0,1,2.41.71,5,5,0,0,1,1.82,2,6.76,6.76,0,0,1,.68,3.07A10.27,10.27,0,0,1,36,407.9a10.57,10.57,0,0,1-2.29,3.52l.22.5,3.73.45a15,15,0,0,0,1.52-3.84,16.14,16.14,0,0,0,.53-4,11.12,11.12,0,0,0-1.14-5.09A8.66,8.66,0,0,0,35.48,396a7.57,7.57,0,0,0-4.18-1.23,6.11,6.11,0,0,0-3.63,1.07,9.32,9.32,0,0,0-2.48,2.57,33,33,0,0,0-2.12,3.95c-.63,1.31-1.18,2.34-1.63,3.09a7.15,7.15,0,0,1-1.62,1.89,3.34,3.34,0,0,1-2.16.75,4.32,4.32,0,0,1-2.27-.66,4.82,4.82,0,0,1-1.77-1.91,6,6,0,0,1-.68-2.93,8.46,8.46,0,0,1,.54-2.93A10.65,10.65,0,0,1,15,396.88l-.18-.65-3.5-.59A17.34,17.34,0,0,0,10,402.15m.14,23.63.27,3.41,23.14,0v.32l-23.32,15v3.78l20.5-.09,8.64.13v-3.27l-9.59.05L16,445.06v-.23l23.32-15.09v-3.82L30.8,426Zm2.77,48.42c0-3,1-5.19,3-6.66s5-2.21,9-2.21q12,0,12,9.1a7.74,7.74,0,0,1-3,6.66q-3,2.21-9.23,2.2-11.73,0-11.73-9.09m-3-.22q0,6.27,3.91,9.65T25,487c4.69,0,8.32-1.07,10.86-3.21s3.82-5.17,3.82-9.11q0-6.31-4-9.73t-11.48-3.41q-6.91,0-10.59,3.21T9.94,474m.27,25.5v3.72L30.58,503l8.77.22v-3.68l-9.09.18Zm0,16.45v19.78L13,536l.36-.23-.27-5.18v-3l17.46-.22,8.77.27V524l-9.09.13L13.12,524v-3l.27-5.09-2.82-.27Zm0,31.91v3.73l20.37-.23,8.77.23v-3.68l-9.09.18Zm26.37,30.28c0,.73,0,1.75-.07,3.07s-.12,2.45-.21,3.38l-6,.14-17-.18q-.19-3.72-.19-6.59,0-9.42,11.46-9.41c4,0,7,.83,9,2.47a8.69,8.69,0,0,1,3,7.12M10,577.67l.18,10.5v0L30.58,588l8.77.23v0c.09-1.52.16-3.16.2-4.94s.07-3.27.07-4.52c0-4.49-1.36-7.92-4.09-10.32s-6.65-3.59-11.78-3.59q-6.63,0-10.18,3.32T10,577.67m-10,26.91c1.55,2.6,3.1,5.5,4.68,8.68l1.64-.64.18-.41q-1.91-4.59-3.91-8.91l-.36-.09-2.14.91ZM36.17,601l.18,7-.05,5.77-6,.14-4.33,0,0-5.18.09-5.09-2.82-.32-.32.27.14,4.82-.09,5.46-9.54-.09-.09-5.78.13-6.86-2.82-.28-.36.19V617.4l20.37-.23,8.77.23V601l-2.82-.27Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M1128.47,536.79"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M1262.18,478q0-16-8.42-23.09t-23.65-7.09a53.62,53.62,0,0,0-26.38,6.39,61.75,61.75,0,0,0-19.28,16.3l0-20.66h-45.17v11.54q17.41,0,17.42,9.12v61.77q0,9.12-17.42,9.12V553H1198V541.44q-13.58,0-13.57-9.12V490.6a49.94,49.94,0,0,1,14.78-19.24,39.2,39.2,0,0,1,9.83-5.67,28.32,28.32,0,0,1,10.43-2,12.89,12.89,0,0,1,10.93,5.47q4.05,5.47,4.05,18.23v45q0,9.12-13.57,9.12V553H1280V541.44q-17.82,0-17.82-9.12Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M1023.34,437.89a14.41,14.41,0,0,0-10.54-4.4,14.81,14.81,0,0,0-10.63,4.31,14.79,14.79,0,0,0-3.25,16.51,15.65,15.65,0,0,0,8,8,14.84,14.84,0,0,0,16.51-3.25,14.83,14.83,0,0,0,4.31-10.63,14.39,14.39,0,0,0-4.39-10.54m2.28,40.05H986.44v10q15.12,0,15.11,8.08v53.41q0,7.89-15.11,7.9v10h54.46v-10q-15.63,0-15.28-7.9Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M888.12,455.72H849.65v10h6.67q7.56,0,7.56,4.75a5.52,5.52,0,0,1-.71,2.8L839.46,530.2,816.1,471.88a6.94,6.94,0,0,1-.53-2.81c0-2.22,1.69-3.34,5.09-3.34h9.48v-10H779.2v10q9.66,0,12.3,6.15l30.21,73.25h22.67l30.21-73.43a8.35,8.35,0,0,1,4.83-4.48,22.8,22.8,0,0,1,8.7-1.49Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M665.59,432.79H626.42v10q15.11,0,15.11,7.9v99.42q0,7.92-15.11,7.91v10h54.45V558q-15.27,0-15.28-7.91Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M486.63,520.54a30.82,30.82,0,0,1-26.17,14.23q-9,0-13.35-3.52t-4.39-9.83a14.29,14.29,0,0,1,2.63-8.79,22.31,22.31,0,0,1,8.09-6.23,79.34,79.34,0,0,1,13.7-5.09q8.25-2.38,19.49-5.19Zm23-35.95q0-9.51-3.43-15.76A26.26,26.26,0,0,0,497,459a39.61,39.61,0,0,0-13.35-5.1,80.16,80.16,0,0,0-15.64-1.5,67.9,67.9,0,0,0-17.82,2.14,45.7,45.7,0,0,0-13.09,5.63A27.09,27.09,0,0,0,429,468a16.17,16.17,0,0,0-2.8,8.78q0,5.79,3.07,9t9.22,3.25q6.15,0,9.31-3.07a10.64,10.64,0,0,0,3.17-8,7.87,7.87,0,0,0-2.82-6.5,12.23,12.23,0,0,0-6.85-2.64,27.55,27.55,0,0,1,9.75-4.74,54.81,54.81,0,0,1,15-1.76,23,23,0,0,1,9.14,1.67,17.38,17.38,0,0,1,10.1,11.33,29.08,29.08,0,0,1,1.14,8.26v5.44q-7.72,2.12-15.54,3.52t-15.12,2.9q-7.29,1.49-13.7,3.34a40.73,40.73,0,0,0-11.24,5,23.58,23.58,0,0,0-7.56,7.82,22.89,22.89,0,0,0-2.72,11.68q0,11.6,7.64,18.36t24.16,6.76a50.22,50.22,0,0,0,12.83-1.58,44.25,44.25,0,0,0,10.71-4.31,37,37,0,0,0,8.17-6.23,29.77,29.77,0,0,0,5.36-7.38,39.41,39.41,0,0,0,1.93,6.68,17.48,17.48,0,0,0,3.51,5.61,17,17,0,0,0,5.62,4,19.79,19.79,0,0,0,8.08,1.49,27.85,27.85,0,0,0,7.82-1.05,15.21,15.21,0,0,0,6.23-3.52,15.7,15.7,0,0,0,4.05-6.51,30.49,30.49,0,0,0,1.4-9.86v-7.92h-8.25v10.38a11.61,11.61,0,0,1-1.23,5.37,4,4,0,0,1-3.69,2.38q-5.28,0-5.27-7.75Z"
				transform="translate(-0.07 -358.02)"
			/>
			<path
				className="logoCalvinEdition3"
				d="M354.77,364.51H326.29l-9.62,24q-11.18-12-33.3-21.24T230.05,358a173.5,173.5,0,0,0-57,9.17A128.37,128.37,0,0,0,126.7,394.9q-19.85,18.53-31.19,47T84.16,509q0,37.07,11.55,64.38t31.75,45.27a131.4,131.4,0,0,0,47.16,26.76,186.64,186.64,0,0,0,58.12,8.78q29.64,0,51.21-8.2t36.56-21.56A114.38,114.38,0,0,0,345,593.86a178.77,178.77,0,0,0,14.82-35.49L334,549.59q-10.39,33.58-33.5,52.09t-63.13,18.51a97,97,0,0,1-37.14-6.87,75.94,75.94,0,0,1-28.87-20.8q-12.14-13.92-18.86-35.1t-6.74-49.8a197.78,197.78,0,0,1,5.39-47.7q5.38-21.76,16.74-37.4a77.56,77.56,0,0,1,29.45-24.43q18.09-8.76,43.5-8.78,34.65,0,56.39,19.47t29.45,51.52l28.1-2.29Z"
				transform="translate(-0.07 -358.02)"
			/>
		</g>
	</svg>
);

class Menu extends React.Component {
	state = {
		dropdownOpen: false,
		openedMenu: null,
		currentScrollHeight: 0,
		// opacity: "rgba(177, 31, 39, 60%)"
		opacity: "#fff"
	};

	dropdownToggleClickHandler = () => {
		this.state.openedMenu && this.state.openedMenu.close();
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
			openedMenu: false
		}));
	};

	closeDropdownHandler = () => {
		this.state.openedMenu && this.state.openedMenu.close();
		this.setState({ dropdownOpen: false, openedMenu: false });
	};

	openMenu = menu => {
		if (menu != this.state.openedMenu) {
			this.state.openedMenu && this.state.openedMenu.close();
			this.setState({ openedMenu: menu });
		}
	};

	render() {
		let toolbarItems = this.props.items
			// .filter(u => auth.hasRoles(u.roles))
			.map(item => (
				<li
					key={item.rank}
					className={
						(item.children ? "submenu" : "") +
						" menuitem align-items-center text-menu"
					}
				>
					{item.link && // Lien sans sous menu
						(item.link.startsWith("https://") ? (
							<a href={item.link} target="_blank">
								{item.name}
							</a>
						) : (
							<Link className="text-menu" to={item.link}>
								{item.name}
							</Link>
						))}
					{item.children && ( // lien avec sous menu
						<>
							{item.name}
							<ul className="flex-column align-items-begin">
								<li className="arrow-down" />
								{item.children
									// .filter(u => auth.hasRoles(u.roles))
									.map(child => (
										<li key={child.rank}>
											{child.link.startsWith("https://") ? (
												<a
													className="text-menu"
													href={child.link}
													target="_blank"
												>
													{child.name}
												</a>
											) : (
												<NavLink className="text-menu" to={child.link}>
													{child.name}
												</NavLink>
											)}
										</li>
									))}
							</ul>
						</>
					)}
				</li>
			));
		return (
			<>
				{/* <div className="container-fluid d-flex toolbar fixed-top flex-row align-items-center" style={{ opacity }}> */}
				<div
					className="container-fluid d-flex toolbar fixed-top flex-row align-items-center"
					style={{ backgroundColor: this.state.opacity }}
					// style={{ backgroundColor: "rgba(177, 31, 39, 0.6)" }}
				>
					<div className="toolbar__logo pl-5 pb-4">
						<Link to="/" onClick={this.closeDropdownHandler}>
							{/* *** maison *** */}
							<LogoCalvinEdition2 width={200} />
							{/* ************************** */}
						</Link>
					</div>
					{/* <div style={{ width:"100px" }}></div> */}
					<div className="ml-auto" />

					<div className="toolbar__items d-none d-xl-flex pr-5">
						<ul className="d-flex flex-row align-items-center">
							{toolbarItems}
						</ul>
					</div>

					<div className="ml-3" />
					<ToggleButton click={this.dropdownToggleClickHandler} />
				</div>

				<Dropdown
					items={this.props.items}
					open={this.state.dropdownOpen}
					click={this.closeDropdownHandler}
					openMenu={this.openMenu}
				/>
				{this.state.dropdownOpen && (
					<Backdrop click={this.closeDropdownHandler} />
				)}
			</>
		);
	}
}

export default Menu;
