// Librairies
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
// CSS
import "./Footer.scss";
// Images
import TwitterIcon from "../../resources/icons/social/twitter.png";
import TwitterShareIcon from "../../resources/icons/social/twitter-gris.png";
import YouTubeIcon from "../../resources/icons/social/youtube.png";
import FacebookShareIcon from "../../resources/icons/social/facebook-gris.png";
import ShareIcon from "../../resources/icons/social/partage.png";
import MailShareIcon from "../../resources/icons/social/email-gris.png";

function toggleClass(itemClassName, toggleClassName) {
	const items = document.getElementsByClassName(itemClassName);
	const action = items[0].classList.contains(toggleClassName)
		? "remove"
		: "add";
	[].forEach.call(items, elem => elem.classList[action](toggleClassName));
}

class Footer extends React.Component {
	state = {
		socialLinks: [],
		shareLinks: [],
		secondFooterLink: [],
		footerLinks: []
	};

	componentDidMount() {
		this.setState({
			socialLinks: [
				{
					rank: 1,
					link: "https://twitter.com/missiontimothee",
					imgAlt: "Twitter",
					imgSrc: TwitterIcon
				},
				{
					rank: 2,
					link: "https://www.youtube.com/channel/UCauQjr16cmV32eH2bX-EIqQ",
					imgAlt: "Youtube",
					imgSrc: YouTubeIcon
				},
				// {
				// 	rank: 3,
				// 	link: 'https://plus.google.com/+MissiontimotheeFr',
				// 	imgAlt: 'Google+',
				// 	imgSrc: GooglePlusIcon
				// },
				{ rank: 4, link: null, imgAlt: "social.more", imgSrc: ShareIcon }
			],
			shareLinks: [
				{
					rank: 5,
					link: "https://twitter.com/intent/tweet?url=",
					imgAlt: "social.share-page",
					socialNetwork: "Twitter",
					imgSrc: TwitterShareIcon
				},
				// {
				// 	rank: 6,
				// 	link: 'https://plus.google.com/share?url=',
				// 	imgAlt: 'social.share-page',
				// 	socialNetwork: 'Google+',
				// 	imgSrc: GooglePlusShareIcon
				// },
				{
					rank: 7,
					link: "https://www.facebook.com/sharer/sharer.php?u=",
					imgAlt: "social.share-page",
					social_network: "Facebook",
					imgSrc: FacebookShareIcon
				},
				{
					rank: 8,
					link: "mailto:?body=",
					imgAlt: "social.share-page-by-mail",
					imgSrc: MailShareIcon
				}
			],
			secondFooterLink: [
				{ rank: 1, link: null, name: "Editions Calvin" },
				{ rank: 2, link: "/cookies", name: "Politique de confidentialité" }

			],
			footerLinks: [

			]
		});
	}
	render() {
		const { t } = this.props;

		const footerLinksStr = this.state.footerLinks.map(menuItem => (
			<div
				className={"col-sm-6 col-md-4 col-lg-4 order-" + menuItem.rank}
				key={menuItem.rank}
			>
				<h5>
					{menuItem.children ? (
						t(menuItem.name)
					) : (
						<Link to={menuItem.link}>{t(menuItem.name)}</Link>
					)}
				</h5>
				{menuItem.children && (
					<ul>
						{menuItem.children.map(subMenuItem => (
							<li key={subMenuItem.rank}>
								<Link to={subMenuItem.link}>{t(subMenuItem.name)}</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		));
		const secondfooterLinksStr = this.state.secondFooterLink.map(footerItem => (
			<div className={"col-md-auto"} key={footerItem.rank}>
				{footerItem.link === null ? (
					<span>
						{footerItem.name === "footer.copyright"
							? t(footerItem.name, { current_year: new Date().getFullYear() })
							: t(footerItem.name)}
					</span>
				) : (
					<Link className="lien" to={footerItem.link}>
						{t(footerItem.name)}
					</Link>
				)}
			</div>
		));

		const socialLinksStr = this.state.socialLinks.map(socialItem => (
			<div className="col-auto" key={socialItem.rank}>
				{socialItem.link !== null ? (
					<a href={socialItem.link}>
						<img
							style={{ width: "40px", height: "40px" }}
							title={t("social.consult-page", {
								social_network: socialItem.imgAlt
							})}
							alt={socialItem.imgAlt}
							src={socialItem.imgSrc}
						/>
					</a>
				) : (
					<img
						title={t(socialItem.imgAlt)}
						alt={t(socialItem.imgAlt)}
						src={socialItem.imgSrc}
						className="cursor-pointer"
						onClick={() => toggleClass("share-links", "share-links-show")}
					/>
				)}
			</div>
		));

		const shareLinksStr = this.state.shareLinks.map(socialItem => (
			<div className="col-auto share-links" key={socialItem.rank}>
				<a href={socialItem.link + encodeURI(window.location)}>
					<img
						title={t(socialItem.imgAlt, {
							social_network: socialItem.socialNetwork
						})}
						alt={t(socialItem.imgAlt, {
							social_network: socialItem.socialNetwork
						})}
						src={socialItem.imgSrc}
					/>
				</a>
			</div>
		));

		return (
			<div>

				<footer className="footer" style={{ backgroundColor: 'rgb(217, 217, 217)' }}>
					<div className="container-fluid">
						<div className="row">{secondfooterLinksStr}</div>
					</div>
				</footer>
			</div>
		);
	}
}

export default withTranslation()(Footer);
