// Libraries
import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MenuWrapper from "../MenuWrapper";
import MetaData from "../MetaData";
import "./Header.scss";
import Connexion from "./Connexion";
import ChoixLangue from "./ChoixLangue";
import Rechercher from "./Rechercher";
import Slide from "./Slide";

class Header extends React.Component {
	componentDidMount() {
		// super.componentDidMount();
	}

	render() {
		const { t, slide } = this.props;

		return (
			<header>
				<MetaData
					title={
						"Calvin Éditions" +
						(slide.h1 ? " - " + slide.h1 : "") +
						(slide.h2 ? " - " + slide.h2 : "") +
						(slide.h3 ? " - " + slide.h3 : "")
					}
					descriptionMeta={slide.descriptionMeta}
				/>
				<MenuWrapper />
				<Slide slide={slide} />
				{/* <div style={{}} className="bg-gris1 pt-3 pb-5 "></div> */}
				{/* BARRE GRISE (CONNEXION, RECHERCHE...) */}
				<div className="container-fluid bg-gris1 no-gutters">
					<div className="container">
						<div className="row connect-language">
							{/* <Rechercher /> */}
							<Connexion />
							{/* <ChoixLangue /> */}
						</div>
					</div>
				</div>
				{/* ************************************************** */}
			</header>
		);
	}
}

export default withTranslation()(Header);
