const lowerExceptions = [
	'a',
	'à',
	'le',
	'la',
	'au',
	'aux',
	'les',
	'de',
	'des',
	'du',
	'et',
	'en',
	'l',
	'd',
	'pas',
	'ne'
];
const upperExceptions = ['jc', 'tv'];

const titleCase = str => {
	if (!str) {
		return str;
	}
	str = str.toLowerCase().replace(')(', ') (');
	let splitChars = [' ', "'", '’', '_', '.'];
	return str
		.split(splitChars[0])
		.map((word, index) => {
			return insideTitleCase(splitChars, 1, word, index);
		})
		.join(splitChars[0]);
};

const insideTitleCase = (splitChars, indiceSplitChar, word, index) => {
	if (
		index !== 0 &&
		lowerExceptions.includes(word.replace(/(\(|\)|'|’|\.)/g, ''))
	) {
		return word;
	}
	if (upperExceptions.includes(word.replace(/(\(|\)|'|’|\.)/g, ''))) {
		return word.toUpperCase();
	}
	if (indiceSplitChar === splitChars.length) {
		if (word.startsWith('(') && word.length >= 2) {
			return word[0] + word.charAt(1).toUpperCase() + word.slice(2);
		}
		return word.charAt(0).toUpperCase() + word.slice(1);
	}
	return word
		.split(splitChars[indiceSplitChar])
		.map(wordW => {
			return insideTitleCase(splitChars, indiceSplitChar + 1, wordW, index);
		})
		.join(splitChars[indiceSplitChar]);
};

const NonBreakingSpace = number => '\u00A0'.repeat(number || 1);

export { titleCase, NonBreakingSpace };
