// Librairies
import React from 'react';
import { Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
// Component
import Header from './Header/Header';

class HeaderAndRoute extends React.Component {
	render() {
		const { t, ...rest } = this.props;
		return (
			<>
				<Header slide={this.props.slide} />
				<Route {...rest} />
			</>
		);
	}
}

export default withTranslation()(HeaderAndRoute);
