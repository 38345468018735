import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ShowMoreText from "react-show-more-text";
import { DATA } from "./Data";
import {
	ModuleEncartListMt,
	ModuleCard,
	ModuleCaroussel,
	Icones,
	BlockImgNlt
} from "@mitim/react-mitim";
import { tools } from "@mitim/react-mitim";
import "./Livre.scss";
import Modal from "react-bootstrap/Modal";

// const DATA = {
//     collection: 'Réforme et réveil',
//     dateParution: '19/01/2020',
//     isbn: '978-2-7560-3573-4',
//     ean: '9782756035734',
//     titre: 'Coronavirus, où est Dieu',
//     sousTitre: '--',
//     auteur: 'John Lennox',
//     editeur: 'Calvin Éditions',
//     typeReliure: 'broché',
//     nombrePages: '256',
//     poids: '0,2530kg',
//     dimensions: '15cm x 22cm',
//     epaisseur: '16 mm'
// }

const Ligne = props => (
	<tr>
		<td className="nom">{props.nom}</td>
		<td className="valeur">{props.valeur}</td>
	</tr>
);

const DetailListe1 = props => (
	<>
		<Ligne nom="Collection" valeur={props.data.collection} />
		<Ligne nom="Date de parution" valeur={props.data.dateParution} />
		<Ligne nom="ISBN" valeur={props.data.isbn} />
		<Ligne nom="EAN" valeur={props.data.ean} />
		<Ligne nom="Titre" valeur={props.data.titre} />
		<Ligne nom="Sous-titre" valeur={props.data.sousTitre} />
		<Ligne nom="Auteur" valeur={props.data.auteur} />
	</>
);

const DetailListe2 = props => (
	<>
		<Ligne nom="Editeur" valeur={props.data.editeur} />
		<Ligne nom="Type de reliure" valeur={props.data.typeReliure} />
		<Ligne nom="Nombre de pages" valeur={props.data.nombrePages} />
		<Ligne nom="Poids" valeur={props.data.poids} />
		<Ligne nom="Dimensions" valeur={props.data.dimensions} />
		<Ligne nom="Épaisseur" valeur={props.data.epaisseur} />
	</>
);

class Livre extends Page {
	state = {
		show: false
	};

	render() {
		const { t } = this.props;
		const BoiteDialogue = props => (
			<>
				<Modal
					show={this.state.show}
					onHide={() => this.setState({ show: false })}
					className=""
				>
					<Modal.Body className="text-center">
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={() => this.setState({ show: false })}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						<img
							className="img-fluid imageCouverture"
							src={`${tools.lienCalvinEdition}/livres/${props.url}`}
							alt=""
						/>
					</Modal.Body>
				</Modal>
			</>
		);

		const id = this.props.match.params.id;

		return (
			<>
				<div
					className="container-fluid bg-white livre no-gutters"
					style={{ minHeight: "580px" }}
				>
					<div className="container pt-5 pb-5">
						<h1 className="text-uppercase" style={{ color: "#000" }}>
							{DATA[id].titre}
						</h1>
						<h2
							className="ft-open-sans"
							style={{ color: "#000", fontSize: "1.3em" }}
						>
							{DATA[id].auteur} | {DATA[id].nombrePages} pages
						</h2>
						<div className="row pt-5">
							<div className="col-md-4">
								<div
									className="blocCouverture"
									onClick={() => {
										this.setState({ show: true });
									}}
								>
									<img
										className="img-fluid imageCouverture"
										src={`${tools.lienCalvinEdition}/livres/${DATA[id].couverture}`}
										alt=""
									/>
									<div className="ombre"></div>
									<div className="texteLien">
										<span className="">
											{" "}
											<i className="ion-ios-search-strong"></i>
										</span>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="pl-md-4">
									<h2
										style={{ color: "#000", fontSize: "2.3em" }}
										className="ft-open-sans pt-5 pt-md-0"
									>
										{DATA[id].prix}€
									</h2>
									<h3
										style={{ color: "#000", fontSize: "1.4em" }}
										className="pt-3"
									>
										Résumé
									</h3>
									<p className="resume" style={{ color: "#000" }}>
										<ShowMoreText
											lines={7}
											more={
												<span
													style={{
														color: "#000",
														fontWeight: "bolder",
														fontSize: "1.1em"
													}}
												>
													<br />
													Lire la suite<i className="ion-chevron-down pl-2"></i>
												</span>
											}
											less={
												<span
													style={{
														color: "#000",
														fontWeight: "bolder",
														fontSize: "1.1em"
													}}
												>
													<br />
													Réduire<i className="ion-chevron-up pl-2"></i>
												</span>
											}
											anchorClass="showMoreText"
											onClick={this.executeOnClick}
											expanded={DATA[id].extendedResume}
										>
											<div style={{ fontSize: "0.9em", textAlign: "justify" }}>
												{DATA[id].resume}
											</div>
										</ShowMoreText>
									</p>
									<h3
										style={{ color: "#000", fontSize: "1.4em" }}
										className="pt-3"
									>
										Télécharger
									</h3>
									<div style={{ color: "#000" }}>
										{DATA[id].fichiers.map(fichier => (
											<div className="">
												<i className="ion-ios-download-outline"></i>
												<a
													className="pl-2"
													style={{ color: "#000" }}
													href={`${tools.lienCalvinEdition}/livres/${fichier.url}`}
													target="_blank"
												>
													{fichier.nom}
												</a>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>

						{DATA[id].lienVideoYoutube && (
							<div className="row pt-5 pb-5">
								<div
									className="center-block col-md-2"
									style={{ float: "none" }}
								></div>
								<div
									className="center-block col-md-8"
									style={{ float: "none" }}
								>
									<div className="video-container " style={{ top: "24px" }}>
										<iframe
											width="560"
											height="315"
											src={DATA[id].lienVideoYoutube}
											frameborder="0"
											allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</div>
								<div
									className="center-block col-md-2"
									style={{ float: "none" }}
								></div>
							</div>
						)}
					</div>
				</div>
				<div
					className="container-fluid pt-5 pb-5 no-gutters"
					style={{ backgroundColor: "#ebebec" }}
				>
					<div className="container">
						<h3 className="text-uppercase text-center pb-5">
							Caractéristiques détaillées
						</h3>

						<div className="blocDetail d-none d-md-block">
							<div className="tableauGauche">
								<table class="livreTable">
									<tbody>
										<DetailListe1 data={DATA[id]} />
									</tbody>
								</table>
							</div>
							<div className="barre"></div>
							<div className="tableauDroit">
								<table class="livreTable">
									<tbody>
										<DetailListe2 data={DATA[id]} />
									</tbody>
								</table>
							</div>
						</div>

						<div className="mobile d-block d-md-none">
							<table class="livreTable">
								<tbody>
									<DetailListe1 data={DATA[id]} />
									<DetailListe2 data={DATA[id]} />
								</tbody>
							</table>
						</div>
						<br />
						<br />
						<br />
						<br />
					</div>
				</div>
				<BoiteDialogue url={DATA[id].couverture} />
			</>
		);
	}
}

export default withTranslation()(Livre);
