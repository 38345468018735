import React from "react";
import { withTranslation } from "react-i18next";

import Menu from "./Menu/Menu";

class MenuWrapper extends React.Component {
	render() {
		const { t } = this.props;
		return (
			<Menu
				items={[
					{
						rank: 1,
						link: "/",
						name: "Accueil",
						roles: ["ROLE_PUBLIC"]
					},
					// {
					// 	rank: 2,
					// 	link: "/",
					// 	name: "Nouveautés",
					// 	roles: ["ROLE_PUBLIC"]
					// },
					{
						rank: 3,
						name: "Catalogue",
						roles: ["ROLE_PUBLIC"],
						children: [
							{
								rank: 1,
								link: "/catalogue/reforme-et-reveil",
								name: "RÉFORME ET RÉVEIL",
								roles: ["ROLE_PUBLIC"]
							},
							{
								rank: 2,
								link: "/catalogue/histoire-et-memoire",
								name: "HISTOIRE ET MÉMOIRE",
								roles: ["ROLE_PUBLIC"]
							},
							{
								rank: 2,
								link: "/catalogue/temoins",
								name: "TÉMOINS",
								roles: ["ROLE_PUBLIC"]
							}
						],
					},
					// {
					// 	rank: 4,
					// 	link: "/",
					// 	name: "Espace pro",
					// 	roles: ["ROLE_PUBLIC"]
					// },
					// {
					// 	rank: 6,
					// 	link: "/",
					// 	name: "La presse en parle",
					// 	roles: ["ROLE_PUBLIC"]
					// },
					{
						rank: 10,
						link: "/contact",
						name: "contact",
						roles: ["ROLE_PUBLIC"]
					}
				]}
			/>
		);
	}
}

export default withTranslation()(MenuWrapper);
