import React from 'react';

const ToggleButton = props => (
	<button
		className="toggle-button d-flex d-xl-none justify-content-around flex-column"
		onClick={props.click}
	>
		<div className="toggle-button__line" />
		<div className="toggle-button__line" />
		<div className="toggle-button__line" />
	</button>
);

export default ToggleButton;
