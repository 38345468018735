import React from "react";
import { withTranslation } from "react-i18next";
import "./BanniereCatalogue.scss";
import { useHistory } from "react-router-dom";

const HistoireEtMemoire = props => {
	const history = useHistory();
	return (
		<div
			className="container-fluid pt-5 pb-5"
			style={{ backgroundColor: "#d9d9d9" }}
		>
			<div className="container pt-4 pb-3">
				<div className="row pb-4">
					<div className="col-md-1"></div>
					<div className="col-md-10">
						<h1
							className={"text-center text-uppercase ft-open " + props.couleur}
							style={{ fontSize: "2.2em" }}
						>
							Collection <strong>{props.titre}</strong>
						</h1>
						<p
							className="text-justify pt-4"
							style={{
								fontSize: "1.3em",
								fontWeight: "bolder",
								color: "#2a292a"
							}}
						>
							{props.paragraphe}
						</p>
					</div>
					<div className="col-md-1"></div>
				</div>
				<div className="blocAutreCollections d-none d-md-block no-gutters">
					<div
						className={"blocTextAutreCollections " + props.couleur}
						style={{}}
					>
						Autres collections
					</div>
					<div
						className={"blocBarreVerticale bg-" + props.couleur}
						style={{}}
					></div>
					<div
						className="texte1 texte"
						onClick={() => {
							history.push(props.liens.lien1.to);
						}}
					>
						<div className={"contenu text-uppercase " + props.couleur}>
							{props.liens.lien1.texte}
							<div className="triangle"></div>
						</div>
					</div>
					<div
						className="texte2 texte"
						onClick={() => {
							history.push(props.liens.lien2.to);
						}}
					>
						<div className={"contenu text-uppercase " + props.couleur}>
							{props.liens.lien2.texte}
							<div className="triangle"></div>
						</div>
					</div>
				</div>
				<div className="d-block d-md-none blocAutreCollectionMobile">
					<h2 className="text-center">Autres collections</h2>
					<div
						className="texte1 texte mt-5"
						onClick={() => {
							history.push(props.liens.lien1.to);
						}}
					>
						<div className={"contenu text-uppercase " + props.couleur}>
							{props.liens.lien1.texte}
							<div className="triangle"></div>
						</div>
					</div>
					<div
						className="texte2 texte mt-3"
						onClick={() => {
							history.push(props.liens.lien2.to);
						}}
					>
						<div className={"contenu text-uppercase " + props.couleur}>
							{props.liens.lien2.texte}
							<div className="triangle"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(HistoireEtMemoire);
