import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import flagEN from '../../resources/icons/language/en.png';
import flagFR from '../../resources/icons/language/fr.png';
import flagKR from '../../resources/icons/language/kr.png';
import flagES from '../../resources/icons/language/es.png';
import flagBG from '../../resources/icons/language/bg.png';
import flagDE from '../../resources/icons/language/de.png';
import { render } from 'react-dom';

const language = {
	fr: flagFR,
	en: flagEN,
	es: flagES,
	kr: flagKR,
	bg: flagBG,
	de: flagDE
};

class ChoixLanguage extends React.Component {
	state = { lang: 'fr' };

	handleLanguageChange = event => {
		const { i18n } = this.props;
		i18n.changeLanguage(event.target.value);
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const { t } = this.props;
		var lg = t('lang') !== 'lang' ? t('lang') : this.state.lang;
		const langs = Object.keys(language).map(key => (
			<MenuItem key={key} value={key}>
				{key !== lg && t('languages.' + key) + ' - '}
				{t('languages.' + key, { lng: key })}
			</MenuItem>
		));
		return (
			<div className="col-auto ml-md-auto">
				<img src={lg && language[lg]} alt={lg} className="mr-2" />
				<Select
					value={lg}
					onChange={this.handleLanguageChange}
					displayEmpty
					name="lang"
				>
					{langs}
				</Select>
			</div>
		);
	}
}
export default withTranslation()(ChoixLanguage);
