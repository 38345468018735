import React from 'react';
import { withTranslation } from 'react-i18next';

class SlideTemplate3 extends React.Component {
	render() {
		const data = this.props.slide;
		const { t } = this.props;

		return (
			<>
				<section style={{ height: '80px' }}></section>
			</>
		);
	}
}
export default withTranslation()(SlideTemplate3);
