import React from "react";
import { withTranslation } from "react-i18next";
import Page from "../Page";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { tools } from "@mitim/react-mitim";
import BanniereCatalogue from "./BanniereCatalogue";
import { DATA } from "../Livre/Data";

const Livre = props => (
	<div className="col-md-4">
		<div className="livreBloc">
			<div className="bloc">
				<Link to={`/livre/${props.data.id}/${props.data.titre}`}>
					<img
						src={`${tools.lienCalvinEdition}/livres/${props.data.couverture}`}
						alt=""
						className="img-fluid"
					/>
					<div className="ombre"></div>
					<div className="texteLien">
						<span className="">VOIR PLUS</span>
					</div>
				</Link>
			</div>
			<div className={"texteBloc rouge " + props.data.couleurBloc}>
				<div className="titre">{props.data.titre}</div>
				<div className="sousTitre">{props.data.auteur}</div>
			</div>
		</div>
	</div>
);

class ReformeEtReveil extends Page {
	render() {
		const { t } = this.props;

		return (
			<>
				<BanniereCatalogue
					titre={<>réforme et réveil</>}
					paragraphe={
						<>
							Portée par un retour aux fondements du protestantisme, la
							collection accueille des textes de la Réforme et du Réveil.
							Anthologies, traités, correspondances, sermons, et autres sont
							ainsi (re)mis en valeur comme « textes-sources ». Leur mise en
							perspective historique rejoint leur actualité pour le plus grand
							profit des lecteurs d’aujourd’hui.
						</>
					}
					couleur="bleu"
					liens={{
						lien1: {
							texte: "Histoire & mémoire",
							to: "/catalogue/histoire-et-memoire"
						},
						lien2: {
							texte: "Témoins",
							to: "/catalogue/temoins"
						}
					}}
				/>

				<div className="container-fluid pt-5 pb-5 no-gutters">
					<div className="container">
						{/* <h1 className="text-center text-uppercase" style={{ color: '#000' }} >Nouveautés</h1> */}
						<div className="row pt-5 pb-5 listeLivre">
							{/* {DATA.map((data, index) => (<Livre data={data} />))} */}

							{/* ################ */}

							{/* {DATA.map((data, index) => (<Livre data={data} />))} */}
							<Livre data={DATA[7]} />
							<Livre data={DATA[3]} />
							<Livre data={DATA[2]} />
							<div className="col-md-4"></div>
							<Livre data={DATA[0]} />
							<div className="col-md-4"></div>

							{/* ################ */}
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withTranslation()(ReformeEtReveil);
