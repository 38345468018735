import React from 'react';
//@ts-ignore
import { Link } from 'react-router-dom';

class BarrePolitique extends React.Component {
	state = {
		visible: true
	};

	render() {
		return (
			<>
				{!(localStorage.getItem('cookies') === 'true') && (
					<div className="container-fluid d-flex fixed-bottom flex-row align-items-center" style={{ backgroundColor: '#999' }}>
						<div className="text-white mt-3 mb-3">
							En poursuivant votre navigation sur ce site, vous acceptez
							l’utilisation de Cookies pour réaliser des statistiques de
							visites.
							<Link to="/cookies" className="pl-2 text-white">
								<u>Plus d'information</u>
							</Link>
						</div>
						<button
							type="button"
							className="btn btn-secondary ml-3"
							onClick={() => {
								localStorage.setItem('cookies', 'true');
								this.setState({ visible: false });
							}}
						>
							Accepter
						</button>
					</div>
				)}
			</>
		);
	}
}

export default BarrePolitique;
